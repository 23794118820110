<template>
  <v-app id="reportInvoice">
    <body class="report-body">
      <div
        v-for="(pageRow, key) in detailPage"
        :id="'report-page-' + key"
        :key="'report-page-' + key"
        class="report-page"
      >
        <header :id="'header_' + key">
          <div class="report-title-section-invoice">
            <div class="report-title-invoice">請求書</div>
          </div>
          <div v-if="key == 1">
            <div class="report-receiver-section-invoice">
              <div class="report-receiver-name-invoice">
                {{ headerData.invoice_customer_name }}&nbsp;&nbsp;
                {{ headerData.invoice_person_in_charge }}&nbsp;様
              </div>
              <div class="report-receiver-address-invoice">
                <span v-if="headerZipCode !== ''">{{ headerZipCode }}</span>
                {{ headerPrefecture }}
                {{ headerData.invoice_address_1 }}
                <span v-if="headerData.invoice_address_2 !== ''">
                  {{ headerData.invoice_address_2 }}
                </span>
              </div>
            </div>
            <div class="report-total-section-invoice">
              <div class="report-total-label-invoice">金額</div>
              <div v-if="invoiceTaxDisplay" class="report-total-price-invoice">
                ¥{{ headerData.invoice_charge_including_tax }} -
                <span class="report-total-tax-invoice">（税込）</span>
              </div>
              <div v-else class="report-total-price-invoice">
                ¥{{ headerData.invoice_charge }} -
              </div>
            </div>
            <div class="report-condition-section-invoice">
              <div
                v-if="headerData.payment_type === 10"
                class="report-condition-label-invoice"
              >
                お支払期限
              </div>
              <div
                v-if="headerData.payment_type === 10"
                class="report-condition-value-invoice"
              >
                {{ headerData.payment_date }}
              </div>
              <div
                class="report-condition-label-invoice report-condition-date-invoice"
              >
                発行日
              </div>
              <div
                class="report-condition-value-invoice report-condition-date-invoice"
              >
                {{ headerData.invoice_creat_date }}
              </div>
            </div>
            <div class="report-slipnumber-section-invoice">
              <div class="report-slipnumber-invoice">
                請求書番号：{{ headerData.invoice_code }}
              </div>
              <div class="report-slipnumber-invoice">
                会員ID：{{ headerData.invoice_customer_code }}
              </div>
            </div>
            <div class="report-payment-section-invoice">
              <div
                v-if="headerData.payment_type === 10"
                class="report-payment-label-invoice"
              >
                下記の口座にお振込みください。振り込み手数料は御社ご負担にてお振込みください。<br />
                三菱UFJ銀行 鳴海支店 普通 1622073 ジエンカブシキガイシヤ
              </div>
              <div v-else class="report-payment-label-invoice">
                お客様ご指定の口座から引落いたします。<br />
                振替予定日 請求書発行日の翌々月6日（休業日の場合、翌営業日）
              </div>
            </div>
            <div class="report-subtotal-section-invoice">
              <div class="report-subtotal-label-invoice">合計</div>
              <div
                v-if="invoiceTaxDisplay"
                class="report-subtotal-value-invoice"
              >
                ¥{{ headerData.invoice_charge_including_tax }}
              </div>
              <div v-else class="report-subtotal-value-invoice">
                ¥{{ headerData.invoice_charge }}
              </div>
              <div
                v-if="invoiceTaxDisplay"
                class="report-subtotal-breakdown-invoice"
              >
                <div
                  v-for="(row, index) in subtotalData"
                  :key="'subtotal-' + index"
                >
                  {{ row }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="report-slipnumber-multiple-section-invoice">
              <div class="report-slipnumber-invoice">
                請求書番号：{{ headerData.invoice_code }}
              </div>
              <div class="report-slipnumber-invoice">
                会員ID：{{ headerData.invoice_customer_code }}
              </div>
            </div>
          </div>
        </header>

        <section
          :id="'detail_' + key"
          :class="
            key == 1
              ? 'report-detail-section-invoice'
              : 'report-detail-multiple-section-invoice'
          "
        >
          <table :id="'table_' + key" class="report-detail-invoice">
            <thead id="thead">
              <tr>
                <th class="report-detail-code-invoice">コード</th>
                <th class="report-detail-name-invoice">品名</th>
                <th class="report-detail-type-invoice">区分</th>
                <th class="report-detail-price-invoice">単価</th>
                <th class="report-detail-quantity-invoice">数量</th>
                <th class="report-detail-amount-invoice">金額</th>
                <th class="report-detail-tax-invoice">税率</th>
              </tr>
              <tr>
                <td colspan="7" class="report-detail-top-invoice"></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in pageRow" :key="'tr-' + index">
                <td class="report-detail-code-invoice">
                  {{ row["invoice_detail_code"] }}
                </td>
                <td class="report-detail-name-invoice">
                  {{ row["invoice_detail_name"] }}
                </td>
                <td class="report-detail-type-invoice">
                  {{ row["use_date_label"] }}
                </td>
                <td class="report-detail-price-invoice">
                  {{ convertNumComma(row["invoice_detail_price"]) }}
                </td>
                <td class="report-detail-quantity-invoice">
                  {{ convertNumComma(row["invoice_detail_quantity"]) }}
                </td>
                <td class="report-detail-amount-invoice">
                  {{ convertNumComma(row["invoice_detail_amount"]) }}
                </td>
                <td v-if="invoiceTaxDisplay" class="report-detail-tax-invoice">
                  {{ row["tax_label"] }}
                </td>
                <td v-else class="report-detail-tax-invoice">---</td>
              </tr>
            </tbody>
          </table>
        </section>
        <div v-if="key == 1" class="report-detail-bottom-section-invoice">
          <div class="report-detail-bottom-invoice" />
        </div>

        <div v-if="key == 1">
          <section id="report-address" class="report-address-section-invoice">
            <div class="report-sender">
              <div class="report-sender">GEN株式会社</div>
              <div class="report-sender">登録番号 : T1-1800-0105-0645</div>
              <div class="report-sender-line" />
              <div v-if="isShibuya">
                <div class="report-sender">〒150-0036</div>
                <div class="report-sender">
                  東京都渋谷区南平台町7-9 DENFLAT南平台2F
                </div>
              </div>
              <div v-else>
                <div class="report-sender">〒153-0061</div>
                <div class="report-sender">
                  東京都目黒区中目黒1-1-17 LANTIQUE BY IOQ 5F
                </div>
              </div>
              <div class="report-sender-line" />
              <div class="report-sender">https://www.gen-square.com</div>
            </div>
          </section>

          <div class="report-sender-stamp-section-invoice">
            <img class="report-sender-stamp" src="@/assets/stamp.png" />
          </div>

          <div class="report-timestamp-section-invoice">
            <div class="report-timestamp-invoice">
              表示日：{{ dateTimeLabel }}
            </div>
          </div>
          <div class="report-pagenumber-section-invoice">
            <div class="report-pagenumber-invoice">
              page {{ key }} / {{ detailPageCount }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="report-timestamp-multiple-section-invoice">
            <div class="report-timestamp-invoice">
              表示日：{{ dateTimeLabel }}
            </div>
          </div>
          <div class="report-pagenumber-multiple-section-invoice">
            <div class="report-pagenumber-invoice">
              page {{ key }} / {{ detailPageCount }}
            </div>
          </div>
        </div>

        <div class="report-logo-section-invoice">
          <img
            class="report-logo-image"
            src="@/assets/images/mygenLogoPaper.svg"
          />
        </div>
      </div>
      <ReportFooter />
    </body>
  </v-app>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router/composables";
import {
  convertNumComma,
  getToday,
  checkShibuyaOffice,
} from "@/utils/utilities.js";
import ReportFooter from "@/components/molecules/ReportFooter.vue";

export default defineComponent({
  name: "ReportInvoice",

  components: { ReportFooter },

  setup() {
    const route = useRoute();
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const isBrother = computed(() => {
      return store.state.auth.isBrother === true;
    });
    const invoiceHeaderId = computed(() => {
      return route.params.id;
    });
    const dateTimeLabel = computed(() => {
      return getToday("jp-datetime");
    });

    const headerData = ref({});
    const subtotalData = ref([]);
    const headerZipCode = computed(() => {
      if (
        "invoice_zip_code" in headerData.value &&
        headerData.value.invoice_zip_code !== "0000000"
      ) {
        return (
          "〒" +
          headerData.value.invoice_zip_code.substr(0, 3) +
          "-" +
          headerData.value.invoice_zip_code.substr(3)
        );
      } else {
        return "";
      }
    });
    const headerPrefecture = computed(() => {
      if (
        "invoice_prefecture" in headerData.value &&
        headerData.value.invoice_prefecture === "その他"
      ) {
        return "";
      } else {
        return headerData.value.invoice_prefecture;
      }
    });
    const detailPage = ref({});
    const detailPageCount = ref(1);
    const invoiceTaxDisplay = ref(true);
    const isShibuya = ref(false);

    const setReportData = (res) => {
      const subtotalDict = res.invoice_subtotal_reference;
      const detailList = res.invoice_detail_list;

      isShibuya.value = checkShibuyaOffice(
        res["invoice_group_reference"]["invoice_date"]
      );

      invoiceTaxDisplay.value =
        res["customer_reference"]["invoice_tax_display"];

      headerData.value = {
        payment_type: res["payment_type"],
        invoice_charge: convertNumComma(res["invoice_charge"]),
        invoice_charge_including_tax: convertNumComma(
          res["invoice_charge_including_tax"]
        ),
        invoice_code: res["invoice_code"],
        invoice_customer_code: res["invoice_customer_code"],
        invoice_customer_name: res["invoice_customer_name"],
        invoice_person_in_charge: res["invoice_person_in_charge"],
        invoice_zip_code: res["invoice_zip_code"],
        invoice_prefecture: res["invoice_prefecture"],
        invoice_address_1: res["invoice_address_1"],
        invoice_address_2: res["invoice_address_2"],
        invoice_creat_date:
          res["invoice_group_reference"]["invoice_date"]
            .replace("-", "年")
            .replace("-", "月") + "日",
        payment_date:
          res["payment_date"].replace("-", "年").replace("-", "月") + "日",
      };

      subtotalData.value = [];
      for (const row of subtotalDict) {
        const line =
          "消費税" +
          row["invoice_subtotal_taxrate"] +
          "%対象 ¥" +
          convertNumComma(row["invoice_subtotal_including_tax"]) +
          "（内消費税¥" +
          convertNumComma(row["invoice_subtotal_tax"]) +
          "）";
        subtotalData.value.push(line);
      }

      const detailFirstCount = 10; // 1ページ目は明細9行
      const detailSecondCount = 27; // 2ページ目以降は明細27行
      let lineCount = 0;
      let isFirst = true;
      let tempPageCount = 1;
      const tempDetailPage = {};
      // detailList は配列（辞書型ではない）なので for of でループ処理
      for (const line of detailList) {
        lineCount++;
        if (isFirst && lineCount % detailFirstCount === 0) {
          // 1ページ目の明細行数
          isFirst = false;
          tempPageCount++;
        } else if (
          !isFirst &&
          (lineCount - detailFirstCount) % detailSecondCount === 0
        ) {
          // 2ページ以降の明細行数
          tempPageCount++;
        }
        if (tempDetailPage[tempPageCount]) {
          tempDetailPage[tempPageCount].push(line);
        } else {
          tempDetailPage[tempPageCount] = [line];
        }
      }
      detailPage.value = tempDetailPage;
      detailPageCount.value = tempPageCount;
    };

    /**
     * api:データ取得
     */
    const loadData = () => {
      let address = "/invoicereport/" + invoiceHeaderId.value + "/";
      if (isStaff.value === false && isBrother.value === false) {
        address += "?customer=" + customerId.value;
      }

      api({
        method: "get",
        url: address,
      }).then((response) => {
        const res = response.data;
        setReportData(res);
      });
    };

    // データ取得
    loadData();

    return {
      convertNumComma,
      customerId,
      isStaff,
      isBrother,
      invoiceHeaderId,
      dateTimeLabel,
      invoiceTaxDisplay,
      isShibuya,

      headerData,
      subtotalData,
      headerZipCode,
      headerPrefecture,
      detailPage,
      detailPageCount,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/report.scss";
</style>
