<template>
  <v-app id="inspire">
    <v-dialog v-model="dialog" fullscreen persistent no-click-animation>
      <FormCustomerCreate />
    </v-dialog>

    <SnackbarMessage />

    <v-app-bar v-if="!drawer" app color="primary" dark outlined>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app color="grey lighten-5">
      <v-container>
        <v-row>
          <v-spacer />
          <v-col align-self="center" class="pt-16 pb-12" v-if="isUser">
            <img
              src="@/assets/images/mygenLogo001.svg"
              style="display: block; margin: auto"
            />
          </v-col>
          <v-col align-self="center" class="pt-16 pb-12" v-if="isStaff">
            <img
              src="@/assets/images/mygenLogo002.svg"
              style="display: block; margin: auto"
            />
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>

      <v-list-item two-line class="mb-5" v-if="isUser">
        <v-list-item-avatar v-if="avatarType >= 1 && avatarType <= 5">
          <img src="@/assets/images/avatar001.jpg" v-if="avatarType === 1" />
          <img src="@/assets/images/avatar002.jpg" v-if="avatarType === 2" />
          <img src="@/assets/images/avatar003.jpg" v-if="avatarType === 3" />
          <img src="@/assets/images/avatar004.jpg" v-if="avatarType === 4" />
          <img src="@/assets/images/avatar005.jpg" v-if="avatarType === 5" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="mygenText--text wrap-text">
            {{ customerName }}
          </v-list-item-title>
          <v-list-item-subtitle class="mygenText--text wrap-text">
            {{ personInCharge }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list>
        <div v-for="(row, index) in itemsList" :key="'row-' + index">
          <div v-for="item in row" :key="'div-' + item.title">
            <div v-if="isUser || (isStaff && isStaffClass(item.class))">
              <div v-if="item.group">
                <v-list-group :key="item.title" class="mygenText--text">
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon>
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="mygenText--text wrap-text">
                      {{ item.title }}
                    </v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="child in item.group"
                    :key="child.title"
                    :to="child.link"
                    link
                    class="mygenText--text"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        {{ child.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
              <div v-else>
                <v-list-item :to="item.link" link class="mygenText--text">
                  <v-list-item-icon>
                    <v-badge
                      v-if="item.count || item.count > 0"
                      :color="item.color ? item.color : 'error'"
                      :content="item.count"
                    >
                      <v-icon>
                        {{ item.icon }}
                      </v-icon>
                    </v-badge>
                    <v-icon v-else>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-badge
                        v-if="item.unlock || item.unlock > 0"
                        color="error"
                        :content="item.unlock"
                      >
                        {{ item.title }}
                      </v-badge>
                      <div v-else>{{ item.title }}</div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </div>
        </div>
        <v-spacer />
        <v-list-item @click="clickLogout" class="mygenText--text">
          <v-list-item-icon>
            <v-icon> bi-door-open </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> LOGOUT </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="mygenBase mygenText--text">
      <div v-if="isLogin">
        <router-view
          :key="$route.fullPath"
          @homeReload="reloadMenu"
          @homeUpdate="updateForm"
        />
      </div>
      <div v-else class="py-10"><ContainerOutOfSession /></div>
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router/composables";
import FormCustomerCreate from "@/views/pages/FormCustomerCreate.vue";
import SnackbarMessage from "@/components/atoms/SnackbarMessage.vue";
import ContainerOutOfSession from "@/components/molecules/ContainerOutOfSession.vue";

export default defineComponent({
  name: "BaseHome",

  components: {
    FormCustomerCreate,
    ContainerOutOfSession,
    SnackbarMessage,
  },

  setup() {
    const router = useRouter();
    const drawer = ref(null);
    const notifications = ref(false);
    const sound = ref(true);
    const widgets = ref(false);
    const customerName = ref("");
    const personInCharge = ref("");
    const form = ref({});

    const isAccountChange = computed(() => {
      return store.state.auth.isAccountChange;
    });
    const customerGroup = [
      {
        title: "お客様情報",
        icon: "",
        link: "/customer",
      },
      {
        title: "請求先情報",
        icon: "",
        link: "/invoice-address",
      },
      {
        title: "パスワード変更",
        icon: "",
        link: "/set-password",
      },
    ];
    if (isAccountChange.value === true) {
      customerGroup.push({
        title: "アカウント変更",
        icon: "",
        link: "/set-email",
      });
    }

    const itemsUser = ref([
      { title: "MENU TOP", icon: "bi-house", link: "/" },
      {
        title: "契約情報",
        icon: "bi-hdd",
        link: "/service-reference",
      },
      {
        title: "GEN COIN",
        icon: "bi-coin",
        link: "/coin-reference",
      },
      {
        title: "請求情報",
        icon: "bi-file-earmark-text",
        link: "/invoice-reference",
      },
      {
        title: "会員登録情報",
        icon: "bi-person-circle",
        group: customerGroup,
      },
      {
        title: "お問い合わせ",
        icon: "bi-envelope",
        link: "/support",
      },
    ]);
    const itemsBrother = ref([
      {
        title: "BROTHER",
        icon: "bi-bootstrap",
        group: [
          {
            title: "担当ユーザー",
            icon: "",
            link: "/brothercustomer-list",
          },
          {
            title: "インセンティブ",
            icon: "",
            link: "/brotherincentive-list",
          },
        ],
        class: [0, 100, 999],
      },
    ]);
    const itemsStaff = ref([
      {
        title: "MENU TOP",
        icon: "bi-house",
        link: "/",
        class: [0, 100, 999],
      },
      {
        key: "bank",
        title: "お客様リスト",
        icon: "bi-people",
        link: "/customer-list",
        class: [100, 999],
        count: 0,
        color: "orange",
        unlock: 0,
      },
      {
        key: "contractFlow",
        title: "契約申込対応",
        icon: "bi-bell",
        link: "/contractflow-list",
        class: [100, 999],
        count: 0,
      },
      {
        key: "coinCharge",
        title: "COIN申込対応",
        icon: "bi-bell",
        link: "/coincharge-list",
        class: [100, 999],
        count: 0,
      },
      {
        key: "contractCancel",
        title: "解約申込対応",
        icon: "bi-bell",
        link: "/contractcancel-list",
        class: [100, 999],
        count: 0,
      },
      {
        title: "請求管理",
        icon: "bi-calculator",
        link: "/invoicegroup-list",
        class: [999],
      },
      {
        title: "支払管理",
        icon: "bi-receipt-cutoff",
        link: "/paymentgroup-list",
        class: [999],
      },
      {
        title: "調整ツール",
        icon: "bi-speedometer2",
        group: [
          {
            title: "請求アジャスト",
            icon: "",
            link: "/invoiceadjustmenttool-list",
          },
          {
            title: "支払アジャスト",
            icon: "",
            link: "/paymentadjustmenttool-list",
          },
          {
            title: "契約更新",
            icon: "",
            link: "/contractrenewaltool-list",
          },
        ],
        class: [999],
      },
      {
        title: "COIN管理",
        icon: "bi-coin",
        link: "/customercoin-list",
        class: [100, 999],
      },
      {
        key: "support",
        title: "お問い合わせ",
        icon: "bi-envelope",
        link: "/support-list",
        class: [999],
        count: 0,
      },
      {
        title: "お知らせ",
        icon: "bi-megaphone",
        link: "/information-list",
        class: [999],
      },
      {
        title: "マスタ",
        icon: "bi-book",
        group: [
          {
            title: "契約プラン",
            icon: "",
            link: "/plan-list",
          },
          {
            title: "契約オプション",
            icon: "",
            link: "/option-list",
          },
          {
            title: "ブラザー",
            icon: "",
            link: "/brother-list",
          },
          {
            title: "クーポン",
            icon: "",
            link: "/coupon-list",
          },
          {
            title: "コインレート",
            icon: "",
            link: "/coinrate-list",
          },
          {
            title: "サポートレート",
            icon: "",
            link: "/supportrate-list",
          },
          {
            title: "インセンティブ",
            icon: "",
            link: "/incentive-list",
          },
          {
            title: "消費税率閲覧",
            icon: "",
            link: "/taxrate-list",
          },
          {
            title: "アカウント",
            icon: "",
            link: "/user-list",
          },
        ],
        class: [999],
      },
      {
        title: "継承ツール",
        icon: "bi-boxes",
        group: [
          {
            title: "顧客リスト",
            icon: "",
            link: "/client-list",
          },
          {
            title: "移行契約",
            icon: "",
            link: "/transferplan-list",
          },
          {
            title: "移行コイン",
            icon: "",
            link: "/transfercoin-list",
          },
        ],
        class: [999],
      },
      {
        title: "操作ログ",
        icon: "bi-file-text",
        link: "/logoperation-list",
        class: [100, 999],
      },
      {
        title: "アカウント",
        icon: "bi-person",
        group: customerGroup,
        class: [0, 100, 999],
      },
      {
        title: "サーバー設定",
        icon: "bi-info-circle",
        link: "/server-setting",
        class: [100, 999],
      },
    ]);

    const dialog = computed(() => {
      return (
        store.state.auth.isLoggedIn === true &&
        store.state.auth.isCustomer === false
      );
    });
    const avatarType = computed(() => {
      return store.state.auth.avatarType;
    });
    const isUser = computed(() => {
      return store.state.auth.isUser === true;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const staffClass = computed(() => {
      return store.state.auth.staffClass;
    });
    const isBrother = computed(() => {
      return store.state.auth.isBrother === true;
    });
    const isLogin = computed(() => {
      return store.state.auth.id !== "";
    });
    const itemsList = computed(() => {
      const list = {};
      if (isUser.value) {
        list["user"] = itemsUser.value;
      }
      if (isStaff.value) {
        list["staff"] = itemsStaff.value;
      }
      if (isBrother.value) {
        list["brother"] = itemsBrother.value;
      }
      return list;
    });

    const loadData = () => {
      if (!isLogin.value) {
        return;
      }
      if (isStaff.value) {
        // api:スタッフ用情報取得
        api({
          method: "get",
          url: "/staffinformation/",
        }).then((response) => {
          // apiで取得したデータをdataにセットする
          const res = response.data;
          const countData = res["staff_information_data"];
          itemsStaff.value.forEach((element) => {
            if (element.key && element.key === "coinCharge") {
              element.count = countData["coin"] || 0;
            } else if (element.key && element.key === "contractCancel") {
              element.count = countData["cancel"] || 0;
            } else if (element.key && element.key === "contractFlow") {
              element.count =
                (countData["reception"] || 0) +
                (countData["setting"] || 0) +
                (countData["release"] || 0);
            } else if (element.key && element.key === "support") {
              element.count = countData["support"] || 0;
            } else if (element.key && element.key === "bank") {
              element.count = countData["bank"] || 0;
              element.unlock = countData["unlock"] || 0;
            }
          });
        });
      } else {
        // api:データ取得
        api({
          method: "get",
          url: "/customer/" + store.state.auth.customerId + "/",
        }).then((response) => {
          const res = response.data;
          customerName.value = res["customer_name"];
          personInCharge.value = res["person_in_charge"];
        });
      }
    };

    const isStaffClass = (classArr) => {
      if (Array.isArray(classArr)) {
        return classArr.includes(staffClass.value);
      }
      return false;
    };
    // ログアウトリンク押下
    const clickLogout = () => {
      store.dispatch("auth/logout");
      store.dispatch("message/setInfoMessage", {
        message: "ログアウトしました。",
      });
      router.replace("/auth/login");
    };
    // ログインリンク押下
    const clickLogin = () => {
      store.dispatch("message/clearMessages");
      router.replace("/auth/login");
    };
    // メニューリロード
    const reloadMenu = () => {
      loadData();
    };
    // 子コンポーネントデータ取得
    const updateForm = (formData) => {
      Object.assign(form, formData);
    };

    // データ取得
    loadData();

    return {
      drawer,
      notifications,
      sound,
      widgets,
      itemsUser,
      itemsBrother,
      itemsStaff,
      customerName,
      personInCharge,
      dialog,
      avatarType,
      isAccountChange,
      isUser,
      isStaff,
      staffClass,
      isBrother,
      isLogin,
      itemsList,

      isStaffClass,
      clickLogout,
      clickLogin,
      reloadMenu,
      updateForm,
    };
  },
});
</script>
