<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-5 py-10'">
    <v-container>
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsIsStaffOnly="isStaffOnly"
            :propsIsBrotherOnly="isBrotherOnly"
            :propsTooltipText="tooltipText"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :key="'data-table-' + formKey"
            :headers="tableHeaders"
            :items="tableItems"
            item-key="id"
            multi-sort
            :search="search"
            :show-expand="tableSettingShowExpand"
            :expanded.sync="expanded"
            :single-expand="true"
            loading-text="Loading... Please wait"
            :no-data-text="dataTableNoDataText"
            :footer-props="dataTableFooterProps"
            :sort-by="tableSortBy"
            @update:sort-by="updateSortBy"
            :sort-desc="tableSortDesc"
            @update:sort-desc="updateSortDesc"
            :items-per-page="tableItemsPerPage"
            @update:items-per-page="updateItemsPerPage"
            class="mygen-table mygenText--text"
          >
            <template v-slot:top>
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- Searchボックス -->
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          class="my-0 py-0"
                        />
                      </v-col>
                      <!-- 日付Search -->
                      <v-col
                        v-if="isSearchDateFrom"
                        cols="12"
                        sm="6"
                        md="3"
                        class="pa-0"
                      >
                        <v-menu
                          :ref="searchDateFromMenu"
                          v-model="searchDateFromMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="searchDateFromValue"
                              :label="searchDateFromLabel"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :readonly="false"
                              @change="loadData()"
                              class="ma-0"
                            />
                          </template>
                          <v-date-picker
                            v-model="searchDateFromValue"
                            no-title
                            locale="jp-ja"
                            :day-format="(date) => new Date(date).getDate()"
                            @input="searchDateFromMenu = false"
                            @change="loadData()"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        v-if="isSearchDateTo"
                        cols="12"
                        sm="6"
                        md="3"
                        class="pa-0"
                      >
                        <v-menu
                          :ref="searchDateToMenu"
                          v-model="searchDateToMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="searchDateToValue"
                              :label="searchDateToLabel"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :readonly="false"
                              @change="loadData()"
                              class="ma-0"
                            />
                          </template>
                          <v-date-picker
                            v-model="searchDateToValue"
                            no-title
                            locale="jp-ja"
                            :day-format="(date) => new Date(date).getDate()"
                            @input="searchDateToMenu = false"
                            @change="loadData()"
                          />
                        </v-menu>
                      </v-col>
                      <!-- セレクタSearch -->
                      <v-col v-if="isSearchSelect" cols="12" sm="6" md="3">
                        <v-select
                          v-model="searchSelectValue"
                          :items="searchSelectItems"
                          :label="searchSelectLabel"
                          item-text="text"
                          item-value="value"
                          @change="loadData()"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <!-- セレクタSubSearch -->
                      <v-col v-if="isSearchSubSelect" cols="12" sm="6" md="3">
                        <v-select
                          v-model="searchSubSelectValue"
                          :items="searchSubSelectItems"
                          :label="searchSubSelectLabel"
                          item-text="text"
                          item-value="value"
                          @change="loadData()"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <v-col
                        v-if="isCustomerExcelExport === true"
                        cols="12"
                        sm="6"
                        md="3"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="excelCustomerExport()"
                        >
                          EXCEL EXPORT
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        v-if="isCoinExcelExport === true"
                        cols="12"
                        sm="6"
                        md="3"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="excelManagementCoinExport()"
                        >
                          EXCEL EXPORT
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        v-if="isCoinDetailExcelExport === true"
                        cols="12"
                        sm="6"
                        md="3"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="excelManagementCoinDetailExport()"
                        >
                          DETAIL EXCEL EXPORT
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer />
                      <v-col cols="12" sm="6" md="3" align="right">
                        <!-- データ追加ダイアログ -->
                        <v-dialog
                          v-model="dialog"
                          :key="'dialog-' + formKey"
                          max-width="600"
                          :fullscreen="$vuetify.breakpoint.xs"
                          persistent
                        >
                          <template
                            v-if="isReadonly === false"
                            v-slot:activator="{ on, attrs }"
                          >
                            <v-btn
                              block
                              dark
                              rounded
                              elevation="4"
                              class="mygen-button font-weight-bold"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ buttonLabel }}
                            </v-btn>
                          </template>
                          <v-card>
                            <FormInvoiceCreate
                              v-if="isInvoiceCreate"
                              :key="'form-invoice-' + formKey"
                              :propsDeleteId="childEditId"
                              @close="closeDialog"
                            />
                            <FormPaymentCreate
                              v-else-if="isPaymentCreate"
                              :key="'form-payment-' + formKey"
                              :propsDeleteId="childEditId"
                              @close="closeDialog"
                            />
                            <FormContractRenewalCreate
                              v-else-if="isRenewalCreate"
                              :key="'form-renewal-' + formKey"
                              :propsDeleteId="childEditId"
                              @close="closeDialog"
                            />
                            <ModelForm
                              v-else
                              :key="'model-' + formKey"
                              :propsFormMode="childMode"
                              :propsFormType="formType"
                              :propsFormDialog="true"
                              :propsEditId="childEditId"
                              @cancel="cancelDialog"
                              @close="closeDialog"
                            />
                          </v-card>
                        </v-dialog>
                        <!-- データハンドリングダイアログ -->
                        <v-dialog
                          v-model="dialogHandleItem"
                          max-width="1000"
                          :fullscreen="$vuetify.breakpoint.xs"
                        >
                          <v-card
                            v-if="
                              dialogHandleType === 'contract' ||
                              dialogHandleType === 'contractreception' ||
                              dialogHandleType === 'contractsetting' ||
                              dialogHandleType === 'contractrelease' ||
                              dialogHandleType === 'contractdelete' ||
                              dialogHandleType === 'contractcancelreception' ||
                              dialogHandleType === 'contractcancelsetting' ||
                              dialogHandleType === 'contractcancelrelease' ||
                              dialogHandleType === 'contractcanceldelete'
                            "
                          >
                            <FormContractHandle
                              :key="'contracthandle-' + formKey"
                              propsDialogType="None"
                              :propsFormType="dialogHandleType"
                              :propsEditId="childEditId"
                              :propsIsCancel="
                                dialogHandleType ===
                                  'contractcancelreception' ||
                                dialogHandleType === 'contractcancelsetting' ||
                                dialogHandleType === 'contractcancelrelease' ||
                                dialogHandleType === 'contractcanceldelete'
                              "
                              @cancel="cancelDialog"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card
                            v-else-if="
                              dialogHandleType === 'coincharge' ||
                              dialogHandleType === 'coinchargedelete'
                            "
                          >
                            <FormCoinHandle
                              :key="'coinhandle-' + formKey"
                              :propsFormType="dialogHandleType"
                              :propsEditId="childEditId"
                              :propsIsCancel="
                                dialogHandleType === 'coinchargedelete'
                              "
                              @cancel="cancelDialog"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card
                            v-else-if="
                              dialogHandleType === 'contractrenewalrelease' ||
                              dialogHandleType === 'customerbank' ||
                              dialogHandleType === 'invoicegroup' ||
                              dialogHandleType === 'paymentgroup' ||
                              dialogHandleType === 'support' ||
                              dialogHandleType === 'brotherdisconnect' ||
                              dialogHandleType === 'transferdisconnect'
                            "
                          >
                            <ModelForm
                              :key="'modelform-' + formKey"
                              propsFormMode="patch"
                              :propsFormType="dialogHandleType"
                              :propsFormDialog="true"
                              :propsEditId="childEditId"
                              @cancel="cancelDialog"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card v-else-if="dialogHandleType === 'incentive'">
                            <FormPaymentCreate
                              :key="'incentive-' + formKey"
                              :propsInvoiceGroupId="childEditId"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card
                            v-else-if="dialogHandleType === 'brotherconnection'"
                          >
                            <FormBrotherConnection
                              :key="'brotherconnection-' + formKey"
                              :propsEditId="childEditId"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card
                            v-else-if="
                              dialogHandleType === 'transferconnection'
                            "
                          >
                            <FormTransferConnection
                              :key="'transferconnection-' + formKey"
                              :propsEditId="childEditId"
                              @close="closeDialog"
                            />
                          </v-card>
                        </v-dialog>
                        <!-- データ設定ダイアログ -->
                        <v-dialog
                          v-model="dialogSettingItem"
                          max-width="1000"
                          :fullscreen="$vuetify.breakpoint.xs"
                        >
                          <v-card
                            v-if="
                              dialogSettingType === 'customersetting' ||
                              dialogSettingType === 'customersettingwithflow'
                            "
                          >
                            <FormContractHandle
                              :key="'customersetting-' + formKey"
                              :propsDialogType="dialogSettingType"
                              propsFormType="customersetting"
                              :propsEditId="childEditId"
                              @cancel="cancelDialog"
                              @close="closeDialog"
                            />
                          </v-card>
                          <v-card v-else-if="dialogSettingType === 'structure'">
                            <FormStructure
                              :key="'structure-' + formKey"
                              :propsEditId="childEditId"
                              :propsPlanName="childEditData['plan_name']"
                              @close="closeDialog"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <!-- InvoiceCheck -->
                    <v-row v-if="isInvoiceCheck === true">
                      <v-spacer />
                      <v-col cols="12" sm="4" md="2" align="right">
                        <v-text-field
                          v-model="checkInvoiceFrom"
                          append-icon="mdi-file-search-outline"
                          label="Invoice Group1"
                          single-line
                          hide-details
                          @input="clearMessage()"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" md="2" align="right">
                        <v-text-field
                          v-model="checkInvoiceTo"
                          append-icon="mdi-file-search-outline"
                          label="Invoice Group2"
                          single-line
                          hide-details
                          @input="clearMessage()"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="3" align="right">
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="excelInvoiceCheckExport()"
                        >
                          Difference CHECK
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- インポート設定 -->
                    <v-row v-if="isCsvImport === true">
                      <v-spacer />
                      <v-col cols="12" sm="6" md="3" align="right">
                        <v-file-input
                          @change="csvImport"
                          accept=".csv"
                          label="CSV Import"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="3" align="right">
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="csvDataExport(0)"
                        >
                          CSV Export
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align="right"
                        v-if="isStructureCsvImport === true"
                      >
                        <v-file-input
                          @change="csvStructureImport"
                          accept=".csv"
                          label="STRUCTURE CSV Import"
                          class="my-0 py-0"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align="right"
                        v-if="isStructureCsvImport === true"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="csvStructureExport()"
                        >
                          STRUCTURE CSV Export
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align="right"
                        v-if="isOptionCsvImport === true"
                      >
                        <v-file-input
                          @change="csvTransferOptionImport"
                          accept=".csv"
                          label="OPTION CSV Import"
                          class="py-0"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align="right"
                        v-if="isOptionCsvImport === true"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="csvTransferOptionExport()"
                        >
                          OPTION CSV Export
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        align="right"
                        v-if="isTransferCheckExcel === true"
                      >
                        <v-btn
                          block
                          color="primary"
                          outlined
                          rounded
                          class="font-weight-bold"
                          @click="excelTransferCheckExport()"
                        >
                          CHECK EXCEL
                          <v-icon right dark> mdi-download </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>

            <!-- リンクデータ（お知らせ） -->
            <template v-slot:[`item.informationLink`]="{ item }">
              <ButtonFloating
                v-if="item.information_link"
                :propsIsHref="true"
                :propsLink="item.information_link"
                propsTooltipText="リンクを開く"
                :propsIcon="'mdi-launch'"
              />
            </template>
            <!-- 情報リンクアイコン -->
            <template v-slot:[`item.customerInfoLink`]="{ item }">
              <ButtonFloating
                v-if="item.info_link"
                :propsIsHref="true"
                :propsLink="item.info_link"
                propsTooltipText="情報リンクへ移動"
                :propsIcon="'mdi-launch'"
              />
              <v-icon v-else size="22" color="grey"> mdi-launch </v-icon>
            </template>
            <!-- ステータスチップ -->
            <template
              v-for="rowChip in getSelectName"
              v-slot:[`item.${rowChip}`]="{ item }"
            >
              <ChipStatus
                :key="'chip-' + rowChip"
                :propsType="rowChip"
                :propsValue="item[rowChip]"
              />
            </template>

            <!-- 共通編集アイコン -->
            <template v-slot:[`item.actions`]="{ item }">
              <div
                v-if="!item.is_lock || item.is_lock === false"
                class="d-flex justify-center"
              >
                <ButtonFloating
                  :propsClick="editItem"
                  :propsParam1="item"
                  :propsIcon="'bi-pen'"
                  class="mx-1"
                />
                <ButtonFloating
                  :propsClick="deleteItem"
                  :propsParam1="item"
                  :propsIcon="'bi-trash'"
                />
              </div>
              <div v-else style="white-space: nowrap">
                <ButtonFloating
                  :propsClick="readItem"
                  :propsParam1="item"
                  :propsIcon="'bi-window'"
                />
              </div>
            </template>
            <!-- 共通削除シングルアイコン -->
            <template v-slot:[`item.delete`]="{ item }">
              <ButtonFloating
                v-if="
                  (isDeleteKey &&
                    item[formObject.setting.isDeleteKey] &&
                    !item.is_lock) ||
                  item.is_lock === false
                "
                :propsClick="deleteItem"
                :propsParam1="item"
                :propsIcon="'bi-trash'"
              />
              <ButtonFloating
                v-else-if="
                  !isDeleteKey && (!item.is_lock || item.is_lock === false)
                "
                :propsClick="deleteItem"
                :propsParam1="item"
                :propsIcon="'bi-trash'"
              />
              <v-icon v-else size="22" color="grey"> bi-trash </v-icon>
            </template>
            <!-- 閲覧アイコン -->
            <template v-slot:[`item.readonly`]="{ item }">
              <ButtonFloating
                :propsClick="readItem"
                :propsParam1="item"
                :propsIcon="'bi-window'"
              />
            </template>
            <!-- 請求書アイコン -->
            <template v-slot:[`item.reportInvoice`]="{ item }">
              <router-link
                :to="{ name: 'reportInvoice', params: { id: item.id } }"
                class="text-decoration-none"
                target="_blank"
              >
                <v-btn fab dark small elevation="0" color="mygenIcon">
                  <v-icon size="22" color="mygenText"> bi-file-earmark </v-icon>
                </v-btn>
              </router-link>
            </template>
            <!-- 支払通知書アイコン -->
            <template v-slot:[`item.reportPayment`]="{ item }">
              <router-link
                :to="{ name: 'reportPayment', params: { id: item.id } }"
                class="text-decoration-none"
                target="_blank"
              >
                <v-btn fab dark small elevation="0" color="mygenIcon">
                  <v-icon size="22" color="mygenText"> bi-file-earmark </v-icon>
                </v-btn>
              </router-link>
            </template>
            <!-- 口座登録用アイコン -->
            <template v-slot:[`item.isBankStatus`]="{ item }">
              <ButtonFloating
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'customerbank'"
                :propsIcon="
                  item.is_bank === 10
                    ? 'bi-check-lg'
                    : 'bi-exclamation-triangle'
                "
              />
            </template>
            <!-- 共通ハンドリングアイコン -->
            <template v-slot:[`item.handleItem`]="{ item }">
              <ButtonFloating
                :propsClick="handleItem"
                :propsParam1="item"
                :propsIcon="'bi-pen'"
              />
            </template>
            <!-- 請求書専用ハンドリングアイコン -->
            <template v-slot:[`item.handleItemInvoiceGroup`]="{ item }">
              <ButtonFloating
                v-if="item.invoice_is_last === true"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'invoicegroup'"
                :propsIcon="
                  item.invoice_group_status === 50
                    ? 'bi-check-lg'
                    : 'bi-toggle-off'
                "
              />
              <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
            </template>
            <!-- 支払専用ハンドリングアイコン -->
            <template v-slot:[`item.handleItemPaymentGroup`]="{ item }">
              <ButtonFloating
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'paymentgroup'"
                :propsIcon="
                  item.payment_group_status < 10
                    ? 'bi-toggle-off'
                    : 'bi-check-lg'
                "
              />
            </template>
            <!-- 契約更新専用ハンドリングアイコン -->
            <template v-slot:[`item.handleItemRenewal`]="{ item }">
              <ButtonFloating
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractrenewalrelease'"
                :propsIcon="
                  item.contract_renewal_status === 50
                    ? 'bi-check-lg'
                    : 'bi-toggle-off'
                "
              />
            </template>
            <!-- 自動振替データハンドリングアイコン -->
            <template v-slot:[`item.excelAutomaticTransfer`]="{ item }">
              <ButtonFloating
                :propsClick="excelAutomaticTransferExport"
                :propsParam1="item"
                :propsColumn="'id'"
                :propsIcon="'bi-file-earmark-excel'"
              />
            </template>
            <!-- インセンティブ計算ハンドリングアイコン -->
            <template v-slot:[`item.handleItemIncentive`]="{ item }">
              <v-icon
                v-if="item.invoice_is_incentive_release === true"
                size="22"
                color="grey"
              >
                bi-check-lg
              </v-icon>
              <v-icon
                v-else-if="item.invoice_is_incentive === true"
                size="22"
                color="grey"
              >
                bi-calculator
              </v-icon>
              <ButtonFloating
                v-else
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'incentive'"
                :propsIcon="'bi-calculator'"
              />
            </template>
            <!-- 共通設定アイコン -->
            <template v-slot:[`item.settingItem`]="{ item }">
              <ButtonFloating
                :propsClick="settingItem"
                :propsParam1="item"
                :propsIcon="'bi-gear-wide-connected'"
                :propsIsDot="
                  item.account_change_setting !== null &&
                  item.account_change_setting === true
                "
                :propsIsDotGreen="
                  item.order_status !== null &&
                  item.contract_flow_status !== null &&
                  item.order_status === 0 &&
                  item.contract_flow_status === 10
                "
              />
            </template>
            <!-- アカウント有効化アイコン -->
            <template v-slot:[`item.isActiveStatus`]="{ item }">
              <v-icon v-if="item.is_active" size="22" color="green">
                bi-check-circle
              </v-icon>
              <v-icon v-else size="22" color="red"> bi-dash-circle</v-icon>
            </template>
            <!-- アカウントスタッフアイコン -->
            <template v-slot:[`item.isStaffStatus`]="{ item }">
              <v-icon v-if="item.is_staff" size="22" color="green">
                bi-check-circle
              </v-icon>
              <v-icon v-else size="22" color="red"> bi-x-circle </v-icon>
            </template>
            <!-- 対応フロー受付アイコン -->
            <template v-slot:[`item.contractReceptionLink`]="{ item }">
              <ButtonFloating
                v-if="item.order_status < 10"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractreception'"
                :propsIcon="'bi-bell'"
              />
              <ButtonFloating
                v-else-if="item.order_status === 10 || item.order_status === 90"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractreception'"
                :propsIcon="'bi-check-lg'"
              />
              <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
            </template>
            <!-- 対応フロー設定アイコン -->
            <template v-slot:[`item.contractSettingLink`]="{ item }">
              <div v-if="item.order_status !== 90">
                <ButtonFloating
                  v-if="item.order_status === 10"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractsetting'"
                  :propsIcon="'bi-bell'"
                />
                <ButtonFloating
                  v-else-if="item.order_status === 20"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractsetting'"
                  :propsIcon="'bi-check-lg'"
                />
                <v-icon
                  v-else-if="item.order_status < 10"
                  size="22"
                  color="grey"
                >
                  bi-bell
                </v-icon>
                <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
              </div>
            </template>
            <!-- 対応フロー公開アイコン -->
            <template v-slot:[`item.contractReleaseLink`]="{ item }">
              <div v-if="item.order_status !== 90">
                <ButtonFloating
                  v-if="item.order_status === 20"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractrelease'"
                  :propsIcon="'bi-bell'"
                />
                <ButtonFloating
                  v-else-if="
                    item.order_status === 50 &&
                    item.related_count === 0 &&
                    item.cancel_count === 0 &&
                    item.contract_plan_is_invoice === false
                  "
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractrelease'"
                  :propsIcon="'bi-check-lg'"
                />
                <v-icon
                  v-else-if="item.order_status < 20"
                  size="22"
                  color="grey"
                >
                  bi-bell
                </v-icon>
                <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
              </div>
            </template>
            <!-- 対応フロー取消アイコン -->
            <template v-slot:[`item.contractDeleteLink`]="{ item }">
              <ButtonFloating
                v-if="
                  item.order_status < 10 &&
                  item.related_count === 0 &&
                  item.cancel_count === 0 &&
                  item.contract_plan_is_invoice === false
                "
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractdelete'"
                :propsIcon="'bi-trash'"
              />
              <v-icon v-else size="22" color="grey"> bi-trash </v-icon>
            </template>
            <!-- COIN受付アイコン -->
            <template v-slot:[`item.coinchargeLink`]="{ item }">
              <div v-if="!item.contract_coin_is_contract_plan">
                <ButtonFloating
                  v-if="item.coin_order_status < 10"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'coincharge'"
                  :propsIcon="'bi-pen'"
                />
                <ButtonFloating
                  v-else-if="item.contract_coin_is_invoice === false"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'coincharge'"
                  :propsIcon="'bi-check-lg'"
                />
                <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
              </div>
            </template>
            <!-- COIN受付取消アイコン -->
            <template v-slot:[`item.coinchargeDeleteLink`]="{ item }">
              <div v-if="!item.contract_coin_is_contract_plan">
                <ButtonFloating
                  v-if="item.coin_order_status < 10"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'coinchargedelete'"
                  :propsIcon="'bi-trash'"
                />
                <v-icon v-else size="22" color="grey"> bi-trash</v-icon>
              </div>
            </template>
            <!-- 解約対応フロー受付アイコン -->
            <template v-slot:[`item.contractCancelReceptionLink`]="{ item }">
              <ButtonFloating
                v-if="item.cancel_order_status < 10"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractcancelreception'"
                :propsIcon="'bi-bell'"
              />
              <ButtonFloating
                v-else-if="
                  item.cancel_order_status === 10 ||
                  item.cancel_order_status === 90
                "
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractcancelreception'"
                :propsIcon="'bi-check-lg'"
              />
              <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
            </template>
            <!-- 解約対応フロー設定アイコン -->
            <template v-slot:[`item.contractCancelSettingLink`]="{ item }">
              <div v-if="item.cancel_order_status !== 90">
                <ButtonFloating
                  v-if="item.cancel_order_status === 10"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractcancelsetting'"
                  :propsIcon="'bi-bell'"
                />
                <ButtonFloating
                  v-else-if="item.cancel_order_status === 20"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractcancelsetting'"
                  :propsIcon="'bi-check-lg'"
                />
                <v-icon
                  v-else-if="item.cancel_order_status < 10"
                  size="22"
                  color="grey"
                >
                  bi-bell
                </v-icon>
                <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
              </div>
            </template>
            <!-- 解約対応フロー公開アイコン -->
            <template v-slot:[`item.contractCancelReleaseLink`]="{ item }">
              <div v-if="item.cancel_order_status !== 90">
                <ButtonFloating
                  v-if="item.cancel_order_status === 20"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractcancelrelease'"
                  :propsIcon="'bi-bell'"
                />
                <ButtonFloating
                  v-else-if="item.cancel_order_status === 50"
                  :propsClick="handleItem"
                  :propsParam1="item"
                  :propsParam2="'contractcancelrelease'"
                  :propsIcon="'bi-check-lg'"
                />
                <v-icon
                  v-else-if="item.cancel_order_status < 20"
                  size="22"
                  color="grey"
                >
                  bi-bell
                </v-icon>
                <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
              </div>
            </template>
            <!-- 対応フロー取消アイコン -->
            <template v-slot:[`item.contractCancelDeleteLink`]="{ item }">
              <ButtonFloating
                v-if="item.cancel_order_status < 10"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'contractcanceldelete'"
                :propsIcon="'bi-trash'"
              />
              <v-icon v-else size="22" color="grey"> bi-trash </v-icon>
            </template>
            <!-- インセンティブ出力アイコン -->
            <template v-slot:[`item.excelPaymentUser`]="{ item }">
              <ButtonFloating
                :propsClick="excelPaymentUserExport"
                :propsParam1="item"
                :propsColumn="'id'"
                :propsIcon="'bi-file-earmark-excel'"
              />
            </template>
            <!-- インセンティブ明細出力アイコン -->
            <template v-slot:[`item.excelPaymentDetail`]="{ item }">
              <ButtonFloating
                :propsClick="excelPaymentDetailExport"
                :propsParam1="item"
                :propsColumn="'id'"
                :propsIcon="'bi-file-earmark-excel'"
              />
            </template>
            <!-- ブラザーコネクトアイコン -->
            <template v-slot:[`item.brotherConnection`]="{ item }">
              <ButtonFloating
                v-if="!item.user || item.user === ''"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'brotherconnection'"
                :propsIcon="'bi-plug'"
              />
              <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
            </template>
            <!-- ブラザー切断アイコン -->
            <template v-slot:[`item.brotherDisconnect`]="{ item }">
              <ButtonFloating
                v-if="item.user && item.user !== ''"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'brotherdisconnect'"
                :propsIcon="'bi-scissors'"
              />
              <v-icon v-else size="22" color="grey"> bi-scissors </v-icon>
            </template>
            <!-- アカウントコネクトアイコン -->
            <template v-slot:[`item.transferConnection`]="{ item }">
              <ButtonFloating
                v-if="!item.is_lock || item.is_lock === false"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'transferconnection'"
                :propsIcon="'bi-plug'"
              />
              <v-icon v-else size="22" color="grey"> bi-check-lg </v-icon>
            </template>
            <!-- アカウント切断アイコン -->
            <template v-slot:[`item.transferDisconnect`]="{ item }">
              <ButtonFloating
                v-if="item.is_lock || item.is_lock === true"
                :propsClick="handleItem"
                :propsParam1="item"
                :propsParam2="'transferdisconnect'"
                :propsIcon="'bi-scissors'"
              />
              <v-icon v-else size="22" color="grey"> bi-scissors </v-icon>
            </template>

            <!-- expanded-item -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="mygenCard">
                <v-container
                  :class="$vuetify.breakpoint.mobile ? 'pa-0 ma-0' : ''"
                >
                  <v-row>
                    <v-col class="my-5">
                      <PanelCustomer
                        v-if="isExpandCustomer || isExpandBrotherCustomer"
                        :key="'list-expand_customer-' + formKey + '-' + item.id"
                        :propsCustomerId="item.id"
                        @update="closeDialog"
                      />
                      <PanelFlow
                        v-if="isExpandFlow"
                        :key="'list-expand_flow-' + formKey + '-' + item.id"
                        :propsContractPlanId="item.id"
                      />
                      <PanelCoinCharge
                        v-if="isExpandCharge"
                        :key="'list-expand_charge-' + formKey + '-' + item.id"
                        :propsContractCoinId="item.id"
                      />
                      <ContainerBrotherInfo
                        v-if="isExpandBrother"
                        :key="'list-expand_brother-' + formKey + '-' + item.id"
                        :propsBrotherId="item.id"
                      />
                      <CardStructure
                        v-if="isExpandStructure"
                        :key="
                          'list-expand_structure-' + formKey + '-' + item.id
                        "
                        :propsPlanId="item.id"
                        @update="closeDialog"
                      />
                      <ModelTable
                        v-if="isExpandInvoice"
                        :key="'list-expand_invoice-' + formKey + '-' + item.id"
                        propsFormType="invoiceheader"
                        propsFilterColumn="invoice_group"
                        :propsFilterId="item.id"
                        @update="closeDialog"
                      />
                      <ModelTable
                        v-if="isExpandPayment"
                        :key="'list-expand_payment-' + formKey + '-' + item.id"
                        propsFormType="paymentheader"
                        propsFilterColumn="payment_group"
                        :propsFilterId="item.id"
                        @update="closeDialog"
                      />
                      <ModelTable
                        v-if="isExpandCoin"
                        :key="'list-expand_coin-' + formKey + '-' + item.id"
                        propsFormType="managementcoinbrother"
                        propsFilterColumn="customer"
                        :propsFilterId="item.id"
                        @update="closeDialog"
                      />
                      <ModelTable
                        v-if="isExpandTransfer"
                        :key="'list-expand_transfer-' + formKey + '-' + item.id"
                        propsFormType="transferoption"
                        propsFilterColumn="transfer_plan"
                        :propsFilterId="item.id"
                        :propsIsAddButton="!item.is_lock"
                        @update="closeDialog"
                      />
                      <ModelDetail
                        v-if="isExpandPaymentDetail"
                        :key="
                          'list-expand_payment_detail-' +
                          formKey +
                          '-' +
                          item.id
                        "
                        propsFormType="paymentbyuser"
                        propsFilterColumn="header"
                        :propsFilterId="item.id"
                        :propsIsSpecifyId="true"
                      />
                      <CardSupport
                        v-if="isExpandSupport"
                        :key="'list-expand_support-' + formKey + '-' + item.id"
                        :propsItem="item"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import { useRoute } from "vue-router/composables";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { createKey } from "@/utils/utilities.js";
import {
  getFormObject,
  getApiParameters,
  setApiToListData,
} from "@/utils/formUtility.js";
import {
  getSelectName,
  getSelectInvoiceGroupData,
} from "@/utils/selectUtility.js";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import {
  exportCustomerExcel,
  exportAutomaticTransferExcel,
  exportManagementCoinExcel,
  exportManagementCoinDetailExcel,
  exportTransferCheckExcel,
  exportPaymentUserExcel,
  exportPaymentDetailExcel,
  exportInvoiceCheckExcel,
} from "@/utils/excelUtility.js";
import { readFileAsync, csvExport } from "@/utils/csvUtility.js";
import api from "@/services/api";
import ButtonFloating from "@/components/atoms/ButtonFloating.vue";
import ChipStatus from "@/components/atoms/ChipStatus.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import CardSupport from "@/components/molecules/CardSupport.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";
import ContainerBrotherInfo from "@/components/organisms/ContainerBrotherInfo.vue";
import CardStructure from "@/components/organisms/CardStructure.vue";
import PanelCoinCharge from "@/components/organisms/PanelCoinCharge.vue";
import PanelCustomer from "@/components/organisms/PanelCustomer.vue";
import PanelFlow from "@/components/organisms/PanelFlow.vue";
import ModelForm from "@/views/model/ModelForm.vue";
import ModelTable from "@/views/model/ModelTable.vue";
import ModelDetail from "@/views/model/ModelDetail.vue";
import FormInvoiceCreate from "@/views/pages/FormInvoiceCreate.vue";
import FormPaymentCreate from "@/views/pages/FormPaymentCreate.vue";
import FormBrotherConnection from "@/views/pages/FormBrotherConnection.vue";
import FormTransferConnection from "@/views/pages/FormTransferConnection.vue";
import FormContractRenewalCreate from "@/views/pages/FormContractRenewalCreate.vue";
import FormStructure from "@/views/pages/FormStructure.vue";
import FormContractHandle from "@/views/pages/FormContractHandle.vue";
import FormCoinHandle from "@/views/pages/FormCoinHandle.vue";

export default defineComponent({
  name: "ModelList",

  components: {
    ButtonFloating,
    ChipStatus,
    OverlayProgress,
    CardSupport,
    DivTitle,
    ModelForm,
    ModelTable,
    ModelDetail,
    ContainerBrotherInfo,
    PanelCustomer,
    PanelFlow,
    PanelCoinCharge,
    CardStructure,
    FormInvoiceCreate,
    FormPaymentCreate,
    FormBrotherConnection,
    FormTransferConnection,
    FormContractRenewalCreate,
    FormStructure,
    FormContractHandle,
    FormCoinHandle,
  },

  props: {
    propsFormType: {
      type: String,
      required: true,
      default: "",
    },
  },

  emits: ["homeReload", "homeUpdate"],

  setup(props, { emit }) {
    const route = useRoute();
    const isLoggedIn = computed(() => {
      return store.state.auth.isLoggedIn;
    });
    const brotherId = computed(() => {
      return store.state.auth.brotherId;
    });

    const formType = ref(props.propsFormType);
    const search = ref("");
    const dialog = ref(false);
    const dialogHandleItem = ref(false);
    const dialogHandleType = ref("");
    const dialogSettingItem = ref(false);
    const dialogSettingType = ref("");
    const isDialogCancel = ref(false);

    const isSearchDateFrom = ref(false);
    const isSearchDateTo = ref(false);
    const searchDateFromValue = ref("");
    const searchDateFromLabel = ref("");
    const searchDateFromMenu = ref(false);
    const searchDateToValue = ref("");
    const searchDateToLabel = ref("");
    const searchDateToMenu = ref(false);
    const isSearchSelect = ref(false);
    const searchSelectValue = ref(-1);
    const searchSelectType = ref("");
    const searchSelectItems = ref([]);
    const searchSelectLabel = ref("");
    const isSearchSubSelect = ref(false);
    const searchSubSelectValue = ref(-1);
    const searchSubSelectType = ref("");
    const searchSubSelectItems = ref([]);
    const searchSubSelectLabel = ref("");
    const checkInvoiceFrom = ref("");
    const checkInvoiceTo = ref("");

    const formObject = getFormObject(formType.value);
    const tooltipText = ref(formObject.tooltipText || []);
    const tableItems = ref([]);
    const tableHeaders = ref(formObject.headers || []);
    const tableSortBy = ref(formObject.setting.listSortBy || []);
    const tableSortDesc = ref(formObject.setting.listSortDesc || []);
    const tableItemsPerPage = ref(10);
    const tableSettingShowExpand = ref(formObject.setting.showExpand || false);
    // ローカルストレージの情報をセット
    if (localStorage.getItem("modelListOptions")) {
      let tempOptions = JSON.parse(localStorage.getItem("modelListOptions"));
      if (!tempOptions) {
        tempOptions = {};
      }
      if ("sortBy" in tempOptions && formType.value in tempOptions["sortBy"]) {
        tableSortBy.value = tempOptions["sortBy"][formType.value];
      }
      if (
        "sortDesc" in tempOptions &&
        formType.value in tempOptions["sortDesc"]
      ) {
        tableSortDesc.value = tempOptions["sortDesc"][formType.value];
      }
      if (
        "itemsPerPage" in tempOptions &&
        formType.value in tempOptions["itemsPerPage"]
      ) {
        tableItemsPerPage.value = tempOptions["itemsPerPage"][formType.value];
      }
    }

    const isCustomerExcelExport = ref(formObject.setting.isCustomerExcelExport);
    const isCoinExcelExport = ref(formObject.setting.isCoinExcelExport);
    const isCoinDetailExcelExport = ref(
      formObject.setting.isCoinDetailExcelExport
    );
    const isCsvImport = ref(formObject.setting.isCsvImport);
    const isStructureCsvImport = ref(formObject.setting.isStructureCsvImport);
    const isOptionCsvImport = ref(formObject.setting.isOptionCsvImport);
    const isTransferCheckExcel = ref(formObject.setting.isTransferCheckExcel);
    const isReadonly = ref(formObject.setting.isListReadonly === true);
    const isLockMode = ref(false);
    const searchDefault = computed(() => {
      return "query" in route &&
        "searchdefault" in route.query &&
        route.query.searchdefault !== null &&
        route.query.searchdefault !== undefined
        ? route.query.searchdefault
        : "";
    });
    const searchSelectDefault = computed(() => {
      return "query" in route &&
        "searchselectdefault" in route.query &&
        route.query.searchselectdefault !== null &&
        route.query.searchselectdefault !== undefined
        ? route.query.searchselectdefault
        : "";
    });
    // 検索ボックスのデフォルト値セット
    if (searchDefault.value !== "") {
      search.value = searchDefault.value;
    }
    if (formObject.searchDateFrom) {
      isSearchDateFrom.value = true;
      if (formObject.searchDateFrom.default !== "") {
        searchDateFromValue.value = formObject.searchDateFrom.default;
      }
      searchDateFromLabel.value = formObject.searchDateFrom.label;
      searchDateFromMenu.value = formObject.searchDateFrom.menu;
    }
    if (formObject.searchDateTo) {
      isSearchDateTo.value = true;
      if (formObject.searchDateTo.default !== "") {
        searchDateToValue.value = formObject.searchDateTo.default;
      }
      searchDateToLabel.value = formObject.searchDateTo.label;
      searchDateToMenu.value = formObject.searchDateTo.menu;
    }
    if (formObject.searchSelect) {
      isSearchSelect.value = true;
      searchSelectValue.value = formObject.searchSelect.value;
      if (searchSelectDefault.value !== "") {
        searchSelectValue.value = parseInt(searchSelectDefault.value);
      }
      searchSelectType.value = formObject.searchSelect.type;
      if (searchSelectType.value === "invoice_group") {
        searchSelectItems.value = getSelectInvoiceGroupData(isLoggedIn);
      } else {
        searchSelectItems.value = formObject.searchSelect.items;
      }
      searchSelectLabel.value = formObject.searchSelect.label;
    }
    if (formObject.searchSubSelect) {
      isSearchSubSelect.value = true;
      searchSubSelectValue.value = formObject.searchSubSelect.value;
      searchSubSelectType.value = formObject.searchSubSelect.type;
      searchSubSelectItems.value = formObject.searchSubSelect.items;
      searchSubSelectLabel.value = formObject.searchSubSelect.label;
    }

    const isDelete = ref(false);
    const editedIndex = ref(-1);
    const itemsKey = ref([]);
    const expanded = ref([]);
    const responseData = ref({});
    const storeMessage = ref("");

    const returnExpandFlag = (type) => {
      if (
        formObject.setting.showExpand &&
        formObject.setting.expandType === type
      ) {
        return true;
      }
      return false;
    };
    const isExpandBrother = computed(() => {
      return returnExpandFlag("brother");
    });
    const isExpandBrotherCustomer = computed(() => {
      return returnExpandFlag("brothercustomer");
    });
    const isExpandCharge = computed(() => {
      return returnExpandFlag("charge");
    });
    const isExpandCoin = computed(() => {
      return returnExpandFlag("coin");
    });
    const isExpandCustomer = computed(() => {
      return returnExpandFlag("customer");
    });
    const isExpandFlow = computed(() => {
      return returnExpandFlag("flow");
    });
    const isExpandInvoice = computed(() => {
      return returnExpandFlag("invoice");
    });
    const isExpandPayment = computed(() => {
      return returnExpandFlag("payment");
    });
    const isExpandPaymentDetail = computed(() => {
      return returnExpandFlag("paymentdetail");
    });
    const isExpandStructure = computed(() => {
      return returnExpandFlag("structure");
    });
    const isExpandSupport = computed(() => {
      return returnExpandFlag("support");
    });
    const isExpandTransfer = computed(() => {
      return returnExpandFlag("transfer");
    });

    const returnCreateFlag = (type) => {
      if (
        formObject.setting.createType &&
        formObject.setting.createType === type
      ) {
        return true;
      }
      return false;
    };
    const isInvoiceCreate = computed(() => {
      return returnCreateFlag("invoice");
    });
    const isPaymentCreate = computed(() => {
      return returnCreateFlag("payment");
    });
    const isRenewalCreate = computed(() => {
      return returnCreateFlag("renewal");
    });
    const buttonLabel = computed(() => {
      if (isInvoiceCreate.value) {
        return "請求書作成";
      }
      if (isRenewalCreate.value) {
        return "一括契約更新";
      }
      return "追加";
    });

    const listTitle = computed(() => {
      return formObject.setting.title + "リスト";
    });
    const isStaffOnly = computed(() => {
      if (formObject.setting.isBrotherOnly) {
        return false;
      }
      return true;
    });
    const isBrotherOnly = computed(() => {
      if (formObject.setting.isBrotherOnly) {
        return true;
      }
      return false;
    });
    const filterColumn = computed(() => {
      return isBrotherOnly.value ? "brother" : "";
    });
    const filterId = computed(() => {
      return isBrotherOnly.value ? brotherId.value : "";
    });

    const childMode = computed(() => {
      return isDelete.value
        ? "delete"
        : isReadonly.value || isLockMode.value
        ? "readonly"
        : editedIndex.value === -1
        ? "post"
        : "patch";
    });
    const childEditId = computed(() => {
      return editedIndex.value === -1 ? "" : itemsKey.value[editedIndex.value];
    });
    const childEditData = computed(() => {
      return editedIndex.value === -1
        ? []
        : tableItems.value[editedIndex.value];
    });
    const isDeleteKey = computed(() => {
      if (formObject.setting.isDeleteKey) {
        return true;
      }
      return false;
    });
    const isInvoiceCheck = computed(() => {
      if (formObject.setting.isInvoiceCheck) {
        return true;
      }
      return false;
    });

    const progress = ref(false);
    const formKey = ref(createKey());

    const updateOptions = (type, value) => {
      // オプションをローカルストレージに保存
      if (value) {
        let tempOptions = JSON.parse(localStorage.getItem("modelListOptions"));
        if (!tempOptions) {
          tempOptions = {};
        }
        if (type in tempOptions === false) {
          tempOptions[type] = {};
        }
        tempOptions[type][formType.value] = value;
        localStorage.setItem("modelListOptions", JSON.stringify(tempOptions));
      }
    };
    const updateSortBy = (value) => {
      updateOptions("sortBy", value);
    };
    const updateSortDesc = (value) => {
      updateOptions("sortDesc", value);
    };
    const updateItemsPerPage = (value) => {
      updateOptions("itemsPerPage", value);
    };

    /**
     * データセット
     */
    const dataDisplay = (res) => {
      // リストセット
      const itemData = setApiToListData(res, formObject.headers);
      tableItems.value = itemData["listData"];
      itemsKey.value = itemData["listKey"];
      formKey.value = createKey();

      if (storeMessage.value) {
        store.dispatch("message/setInfoMessage", {
          message: storeMessage.value,
        });
        storeMessage.value = "";
      }
    };

    /**
     * api:データ取得
     */
    const loadData = () => {
      progress.value = true;
      // データ取得アドレス生成
      let address = formObject.setting.apiAddress;
      if (formObject.setting.apiListAddress !== undefined) {
        address = formObject.setting.apiListAddress;
      }

      // セレクトサーチ判定
      if (
        searchSelectType.value === "invoice_group" &&
        searchSelectValue.value === null
      ) {
        responseData.value = {};
        dataDisplay([]);
        progress.value = false;
        return;
      }

      // 検索条件パラメータ作成
      let param = getApiParameters(
        formObject,
        filterColumn,
        filterId,
        isSearchSelect,
        searchSelectValue,
        isSearchSubSelect,
        searchSubSelectValue,
        searchDateFromValue,
        searchDateToValue
      );

      if (param.length > 0) {
        address += "?" + param;
      }

      // データ取得
      api({
        method: "get",
        url: address,
      })
        .then((response) => {
          const res = response.data;
          responseData.value = res;
          dataDisplay(res);
        })
        .then(() => {
          progress.value = false;
        })
        .catch(() => {
          progress.value = false;
        });
    };

    const editItem = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialog.value = true;
    };

    const deleteItem = (item) => {
      isDelete.value = true;
      editedIndex.value = tableItems.value.indexOf(item);
      dialog.value = true;
    };

    const readItem = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      isLockMode.value = true;
      dialog.value = true;
    };

    const handleItem = (item, type) => {
      for (const row of formObject.headers) {
        if (row["value"] === "handleItem") {
          dialogHandleType.value = row["handleType"];
        }
      }
      if (
        type === "contractreception" ||
        type === "contractsetting" ||
        type === "contractrelease" ||
        type === "contractdelete" ||
        type === "contractcancelreception" ||
        type === "contractcancelsetting" ||
        type === "contractcancelrelease" ||
        type === "contractcanceldelete" ||
        type === "contractrenewalrelease" ||
        type === "customerbank" ||
        type === "invoicegroup" ||
        type === "paymentgroup" ||
        type === "incentive" ||
        type === "coincharge" ||
        type === "coinchargedelete" ||
        type === "support" ||
        type === "brotherconnection" ||
        type === "brotherdisconnect" ||
        type === "transferconnection" ||
        type === "transferdisconnect"
      ) {
        dialogHandleType.value = type;
      }
      editedIndex.value = tableItems.value.indexOf(item);
      dialogHandleItem.value = true;
    };

    const settingItem = (item) => {
      for (const row of formObject.headers) {
        if (row["value"] === "settingItem") {
          dialogSettingType.value = row["settingType"];
        }
      }
      editedIndex.value = tableItems.value.indexOf(item);
      dialogSettingItem.value = true;
    };

    const cancelDialog = (message) => {
      isDialogCancel.value = true; // キャンセルセット
      editedIndex.value = -1;
      dialog.value = false;
      dialogHandleItem.value = false;
      dialogHandleType.value = "";
      dialogSettingItem.value = false;
      dialogSettingType.value = "";
      isDelete.value = false; // 初期化
      isLockMode.value = false; // 初期化
      if (message) {
        storeMessage.value = message;
      }
    };

    const closeDialog = (message) => {
      editedIndex.value = -1;
      expanded.value = [];
      dialog.value = false;
      dialogHandleItem.value = false;
      dialogHandleType.value = "";
      dialogSettingItem.value = false;
      dialogSettingType.value = "";
      isDelete.value = false; // 初期化
      isLockMode.value = false; // 初期化
      if (message) {
        storeMessage.value = message;
      }
      emit("homeUpdate");
      // メインメニューのリロード
      emit("homeReload");
      loadData();
    };

    const clearMessage = () => {
      store.dispatch("message/clearMessages");
    };

    const checkLogin = () => {
      if (!isLoggedIn) {
        store.dispatch("message/setErrorMessage", {
          message: "再ログインしてください。",
        });
        return false;
      }
      return true;
    };

    /**
     * お客様リストExcel出力
     */
    const excelCustomerExport = () => {
      if (!checkLogin()) return;
      if (!formObject.excelCustomerFormat) return;
      exportCustomerExcel(formObject);
      return;
    };

    /**
     * 自動口座振替データExcel出力
     */
    const excelAutomaticTransferExport = (group) => {
      if (!checkLogin()) return;
      if (group.length === 0 || !formObject.excelAutomaticTransferFormat)
        return;
      exportAutomaticTransferExcel(formObject, group);
      return;
    };

    /**
     * コイン管理Excel出力
     */
    const excelManagementCoinExport = () => {
      if (!checkLogin()) return;
      if (!formObject.excelManagementCoinFormat) return;
      exportManagementCoinExcel(formObject);
      return;
    };

    /**
     * コイン管理明細Excel出力
     */
    const excelManagementCoinDetailExport = () => {
      if (!checkLogin()) return;
      if (!formObject.excelManagementCoinDetailFormat) return;
      exportManagementCoinDetailExcel(formObject);
      return;
    };

    /**
     * 移行確認用Excel出力
     */
    const excelTransferCheckExport = () => {
      if (!checkLogin()) return;
      if (!formObject.excelTransferCheckFormat) return;
      exportTransferCheckExcel(formObject);
      return;
    };

    /**
     * 支払データExcel出力
     */
    const excelPaymentUserExport = (header) => {
      if (!checkLogin()) return;
      if (header.length === 0 || !formObject.excelPaymentUserFormat) return;
      exportPaymentUserExcel(formObject, header);
      return;
    };

    /**
     * 支払明細データExcel出力
     */
    const excelPaymentDetailExport = (header) => {
      if (!checkLogin()) return;
      if (header.length === 0 || !formObject.excelPaymentDetailFormat) return;
      exportPaymentDetailExcel(formObject, header);
      return;
    };

    /**
     * 請求書確認用Excel出力
     */
    const excelInvoiceCheckExport = () => {
      if (!checkLogin()) return;
      if (!isInvoiceCheck || !formObject.excelDifferenceCheckFormat) {
        store.dispatch("message/setErrorMessage", {
          message: "Difference Check 設定が正しくありません。",
        });
        return false;
      }
      // 値チェック
      if (!checkInvoiceFrom.value) {
        store.dispatch("message/setErrorMessage", {
          message: "Invoice Group1 を指定してください。",
        });
        return false;
      }
      if (!checkInvoiceTo.value) {
        store.dispatch("message/setErrorMessage", {
          message: "Invoice Group2 を指定してください。",
        });
        return false;
      }

      progress.value = true;
      if (
        exportInvoiceCheckExcel(
          formObject,
          checkInvoiceFrom.value,
          checkInvoiceTo.value
        )
      ) {
        progress.value = false;
      } else {
        progress.value = false;
        store.dispatch("message/setErrorMessage", {
          message: "Invoice Group データが存在しません。",
        });
      }
    };

    /**
     * インポートベース
     */
    const importBase = (type, file) => {
      progress.value = true;
      api({
        method: "get",
        url: "/transfersetting/",
      }).then((response) => {
        const settingData = response.data;
        readFileAsync(file, formObject, type, settingData)
          .then(() => {
            loadData();
            progress.value = false;
          })
          .then(() => {
            const message = "インポートしました";
            store.dispatch("message/setInfoMessage", {
              message: message,
            });
          })
          .catch((e) => {
            console.log(e);
            progress.value = false;
          });
      });
    };

    /**
     * csvインポート
     */
    const csvImport = async (file) => {
      if (!checkLogin()) return;
      importBase(0, file);
    };

    /**
     * csvインポート（オプション設定用）
     */
    const csvStructureImport = async (file) => {
      if (!checkLogin()) return;
      importBase(10, file);
    };

    /**
     * csvインポート（移行契約オプション用）
     */
    const csvTransferOptionImport = async (file) => {
      if (!checkLogin()) return;
      importBase(20, file);
    };

    /**
     * csvエクスポート
     */
    const csvDataExport = (type = 0, data = responseData.value) => {
      csvExport(formObject, type, data);
    };

    /**
     * csvエクスポート（オプション設定用）
     */
    const csvStructureExport = () => {
      if (!checkLogin()) return;
      progress.value = true;
      // データ取得
      api({
        method: "get",
        url: "/structure/",
      })
        .then((response) => {
          const res = response.data;
          csvExport(formObject, 10, res);
        })
        .then(() => {
          progress.value = false;
        })
        .catch(() => {
          progress.value = false;
        });
    };

    /**
     * csvエクスポート（移行契約オプション用）
     */
    const csvTransferOptionExport = () => {
      if (!checkLogin()) return;
      progress.value = true;
      // データ取得
      api({
        method: "get",
        url: "/transferoption/",
      })
        .then((response) => {
          const res = response.data;
          csvExport(formObject, 20, res);
        })
        .then(() => {
          progress.value = false;
        })
        .catch(() => {
          progress.value = false;
        });
    };

    onMounted(() => {
      clearMessage();
    });

    watch(dialog, (val) => {
      if (isDialogCancel.value) {
        isDialogCancel.value = false;
      } else {
        val || closeDialog();
        formKey.value = createKey();
      }
    });
    watch(dialogHandleItem, (val) => {
      if (isDialogCancel.value) {
        isDialogCancel.value = false;
      } else {
        val || closeDialog();
        formKey.value = createKey();
      }
    });
    watch(dialogSettingItem, (val) => {
      if (isDialogCancel.value) {
        isDialogCancel.value = false;
      } else {
        val || closeDialog();
        formKey.value = createKey();
      }
    });

    // データ取得
    loadData();

    // 画面遷移時（メニューリロード）
    emit("homeReload");

    return {
      getSelectName,
      dataTableNoDataText,
      dataTableFooterProps,

      formType,
      formObject,
      tooltipText,
      tableHeaders,
      tableItems,
      tableSortBy,
      tableSortDesc,
      tableItemsPerPage,
      tableSettingShowExpand,

      search,
      dialog,
      dialogHandleItem,
      dialogHandleType,
      dialogSettingItem,
      dialogSettingType,
      isSearchDateFrom,
      isSearchDateTo,
      searchDateFromValue,
      searchDateFromLabel,
      searchDateFromMenu,
      searchDateToValue,
      searchDateToLabel,
      searchDateToMenu,
      isSearchSelect,
      searchSelectValue,
      searchSelectType,
      searchSelectItems,
      searchSelectLabel,
      isSearchSubSelect,
      searchSubSelectValue,
      searchSubSelectType,
      searchSubSelectItems,
      searchSubSelectLabel,
      checkInvoiceFrom,
      checkInvoiceTo,

      isCustomerExcelExport,
      isCoinExcelExport,
      isCoinDetailExcelExport,
      isCsvImport,
      isStructureCsvImport,
      isOptionCsvImport,
      isTransferCheckExcel,

      isReadonly,
      isLockMode,
      isDelete,
      editedIndex,
      itemsKey,
      expanded,
      responseData,
      storeMessage,

      isExpandBrother,
      isExpandBrotherCustomer,
      isExpandCharge,
      isExpandCoin,
      isExpandCustomer,
      isExpandFlow,
      isExpandInvoice,
      isExpandPayment,
      isExpandPaymentDetail,
      isExpandStructure,
      isExpandSupport,
      isExpandTransfer,

      isInvoiceCreate,
      isPaymentCreate,
      isRenewalCreate,
      buttonLabel,

      searchSelectDefault,
      isLoggedIn,
      brotherId,
      listTitle,
      isStaffOnly,
      isBrotherOnly,
      childMode,
      childEditId,
      childEditData,
      isDeleteKey,
      isInvoiceCheck,

      progress,
      formKey,

      updateSortBy,
      updateSortDesc,
      updateItemsPerPage,
      dataDisplay,
      loadData,

      editItem,
      deleteItem,
      readItem,
      handleItem,
      settingItem,
      cancelDialog,
      closeDialog,
      clearMessage,

      excelCustomerExport,
      excelAutomaticTransferExport,
      excelManagementCoinExport,
      excelManagementCoinDetailExport,
      excelTransferCheckExport,
      excelPaymentUserExport,
      excelPaymentDetailExport,
      excelInvoiceCheckExport,

      csvImport,
      csvStructureImport,
      csvTransferOptionImport,
      csvDataExport,
      csvStructureExport,
      csvTransferOptionExport,
    };
  },
});
</script>
