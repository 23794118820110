const setting = {
  title: "インセンティブ",
  apiAddress: "/paymentheader/",
  apiListParam: "payment_group_status=50",
  isBrotherOnly: true,
  isListReadonly: true,
  listSortBy: ["payment_group_reference___payment_date"],
  listSortDesc: [true],
  showExpand: true,
  expandType: "paymentdetail",
};

const forms = [];

const headers = [
  {
    text: "ブラザーコード",
    value: "brother_reference___brother_code",
  },
  {
    text: "支払日",
    value: "payment_group_reference___payment_date",
    align: "start",
  },
  {
    text: "対象金額",
    value: "payment_subject_charge",
    type: "number",
    align: "end",
  },
  {
    text: "支払金額",
    value: "payment_charge",
    type: "number",
    align: "end",
  },
  {
    text: "支払税込金額",
    value: "payment_charge_including_tax",
    type: "number",
    align: "end",
  },
  {
    text: "Excel",
    value: "excelPaymentUser",
    align: "center",
    sortable: false,
  },
  {
    text: "明細Excel",
    value: "excelPaymentDetail",
    align: "center",
    sortable: false,
  },
  {
    text: "支払通知書",
    value: "reportPayment",
    align: "center",
    sortable: false,
    nonExcel: true,
  },
  {
    text: "明細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const excelPaymentUserFormat = [
  { label: "ブラザーコード", wpx: 80 },
  { label: "ブラザー名", wpx: 120 },
  { label: "お客様コード", wpx: 80 },
  { label: "お客様名", wpx: 140 },
  { label: "対象金額", wpx: 70 },
  { label: "インセンティブ金額", wpx: 70 },
];

const excelPaymentDetailFormat = [
  { label: "ブラザーコード", wpx: 80 },
  { label: "ブラザー名", wpx: 120 },
  { label: "お客様", wpx: 200 },
  { label: "契約コード", wpx: 140 },
  { label: "種別", wpx: 70 },
  { label: "対象", wpx: 100 },
  { label: "月度", wpx: 90 },
  { label: "対象金額", wpx: 70 },
  { label: "レート", wpx: 60 },
  { label: "インセンティブ", wpx: 70 },
];

export default {
  setting,
  forms,
  headers,
  excelPaymentUserFormat,
  excelPaymentDetailFormat,
};
