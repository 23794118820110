<template>
  <div>
    <div v-if="form.type === 'divider'">
      <v-divider />
      <div class="mt-5">
        <v-icon style="vertical-align: -2px" color="primary">
          {{ form.icon }}
        </v-icon>
        <span class="primary--text text-h6 font-weight-bold">
          {{ form.label }}
        </span>
      </div>
    </div>
    <v-text-field
      v-if="form.type === 'text'"
      v-model="form.value"
      :label="form.label"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :disabled="
        mode === 'post' ||
        mode === 'put' ||
        mode === 'patch' ||
        mode === 'renew'
          ? false
          : true
      "
      @input="controlChange()"
      @change="controlChange()"
    />
    <v-textarea
      v-if="form.type === 'textarea'"
      v-model="form.value"
      counter
      :label="form.label"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :disabled="
        mode === 'post' ||
        mode === 'put' ||
        mode === 'patch' ||
        mode === 'renew' ||
        mode === 'readonly'
          ? false
          : true
      "
      :readonly="mode === 'readonly' ? true : false"
      @input="controlChange()"
      @change="controlChange()"
    />
    <v-text-field
      v-if="form.type === 'password'"
      v-model="form.value"
      :label="form.label"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :append-icon="form.append ? 'mdi-eye' : 'mdi-eye-off'"
      :type="form.append ? 'text' : 'password'"
      :disabled="
        mode === 'post' ||
        mode === 'put' ||
        mode === 'patch' ||
        mode === 'renew'
          ? false
          : true
      "
      @click:append="form.append = !form.append"
    />
    <v-text-field
      v-if="form.type === 'number'"
      v-model.number="form.value"
      type="number"
      :label="form.label"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :disabled="
        form.disabled === true
          ? true
          : mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
          ? false
          : true
      "
      :min="form.min"
      @input="controlChange()"
      @change="controlChange()"
    />
    <v-select
      v-if="form.type === 'select'"
      v-model="form.value"
      :label="form.label"
      item-text="text"
      item-value="value"
      :items="form.items"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :disabled="
        mode === 'post' ||
        mode === 'put' ||
        mode === 'patch' ||
        mode === 'renew'
          ? false
          : true
      "
      return-object
      @change="controlChange()"
    />
    <v-select
      v-if="
        form.type === 'selectBrother' ||
        form.type === 'selectUser' ||
        form.type === 'selectAccount' ||
        form.type === 'selectCustomer' ||
        form.type === 'selectBrotherAccount' ||
        form.type === 'selectTransferUser' ||
        form.type === 'selectClient' ||
        form.type === 'selectPlan' ||
        form.type === 'selectOption' ||
        form.type === 'selectSupportRate' ||
        form.type === 'selectTaxRate' ||
        form.type === 'selectTaxRateValue' ||
        form.type === 'selectCoupon' ||
        form.type === 'selectIncentive' ||
        form.type === 'selectIncentiveFirst' ||
        form.type === 'selectIncentiveMonth'
      "
      v-model="form.value"
      :label="form.label"
      item-text="text"
      item-value="value"
      :items="form.items"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      :disabled="
        mode === 'post' ||
        mode === 'put' ||
        mode === 'patch' ||
        mode === 'renew'
          ? false
          : true
      "
      return-object
      @change="controlChange()"
    />
    <v-text-field
      v-if="form.type === 'datetime'"
      v-model="form.value"
      :label="form.label"
      :rules="form.rules"
      persistent-hint
      :hint="form.hint"
      disabled="true"
    />

    <v-radio-group v-if="form.type === 'radio'" v-model="form.value" row>
      <v-radio
        v-for="(row, index) in form.items"
        :key="'radio-' + index"
        :label="row['text']"
        :value="row['value']"
        :color="row['color']"
      />
    </v-radio-group>

    <v-checkbox
      v-if="form.type === 'checkbox'"
      v-model="form.value"
      :label="form.label"
      :rules="form.rules"
    />

    <v-menu
      v-if="form.type === 'date'"
      :ref="form.menu"
      v-model="form.menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="form.value"
          :label="form.label"
          prepend-icon="mdi-calendar"
          :rules="form.rules"
          persistent-hint
          :hint="form.hint"
          v-bind="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? attrs
              : ''
          "
          v-on="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? on
              : ''
          "
          :disabled="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? false
              : true
          "
          :readonly="false"
        />
      </template>
      <v-date-picker
        v-model="form.value"
        no-title
        locale="jp-ja"
        :day-format="(date) => new Date(date).getDate()"
        @input="form.menu = false"
      />
    </v-menu>

    <v-menu
      v-if="form.type === 'dateMonthFirst' || form.type === 'dateMonthEnd'"
      :ref="form.menu"
      v-model="form.menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="form.value"
          :label="form.label"
          prepend-icon="mdi-calendar"
          :rules="form.rules"
          persistent-hint
          :hint="form.hint"
          v-bind="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? attrs
              : ''
          "
          v-on="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? on
              : ''
          "
          :disabled="
            mode === 'post' ||
            mode === 'put' ||
            mode === 'patch' ||
            mode === 'renew'
              ? false
              : true
          "
          :readonly="form.write ? false : true"
        />
      </template>
      <v-date-picker
        v-model="form.value"
        no-title
        locale="jp-ja"
        type="month"
        @input="form.menu = false"
      />
    </v-menu>

    <v-switch
      v-if="form.type === 'switch'"
      v-model="form.value"
      inset
      :label="form.label"
    />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "FormControls",

  props: {
    propsMode: { type: String, required: true },
    propsForm: { type: Object, required: true },
  },

  emits: ["update"],

  setup(props, { emit }) {
    const mode = ref(props.propsMode);
    const form = ref(props.propsForm);
    const isLoggedIn = computed(() => {
      return store.state.auth.isLoggedIn;
    });

    const loadData = () => {
      const list = {
        selectUser: {
          api: "user",
          code: "email",
          name: "",
          default: "Non",
        },
        selectAccount: {
          api: "customer",
          code: "email",
          name: "customer_name",
          default: "Non",
        },
        selectCustomer: {
          api: "customer",
          code: "customer_code",
          name: "customer_name",
          default: "Non",
        },
        selectBrotherAccount: {
          api: "brotheraccount",
          id: "user_id",
          code: "email",
          name: "customer_name",
          default: "Non",
        },
        selectTransferUser: {
          api: "transferuser",
          code: "email",
          name: "customer_name",
          default: "Non",
        },
        selectBrother: {
          api: "brother",
          code: "brother_code",
          name: "brother_name",
          default: "GEN",
        },
        selectClient: {
          api: "client",
          code: "client_code",
          name: "client_name",
          default: "Non（未選択）",
        },
        selectPlan: {
          api: "plan",
          code: "plan_code",
          name: "plan_name",
          default: "Non（未選択）",
        },
        selectOption: {
          api: "option",
          code: "option_code",
          name: "option_name",
          default: "Non（未選択）",
        },
        selectSupportRate: {
          api: "supportrate",
          code: "support_rate",
          name: "",
          default: "Non（未選択）",
        },
        selectTaxRate: {
          api: "taxrate",
          code: "tax_rate",
          name: "",
          default: "Non（未選択）",
        },
        selectTaxRateValue: {
          api: "taxrate",
          id: "tax_rate",
          code: "tax_rate",
          name: "",
          default: "Non（未選択）",
        },
        selectCoupon: {
          api: "coupon",
          code: "coupon_code",
          name: "coupon_name",
          default: "Non（未選択）",
          ordering: "coupon_sort",
        },
        selectIncentive: {
          api: "incentive",
          code: "incentive_name",
          name: "",
          default: "Non",
        },
        selectIncentiveFirst: {
          api: "incentive",
          code: "incentive_name",
          name: "",
          default: "Non",
        },
        selectIncentiveMonth: {
          api: "incentive",
          code: "incentive_name",
          name: "",
          default: "Non",
        },
      };
      if (form.value.type in list) {
        const setList = list[form.value.type];
        let apiAddress = "/" + setList["api"] + "/";
        if (form.value.type === "selectClient" && mode.value === "post") {
          apiAddress += "?customer__isnull=true";
        }
        if ("ordering" in setList && setList["ordering"] !== "") {
          apiAddress += "?ordering=" + setList["ordering"];
        }
        if (isLoggedIn.value) {
          api({
            method: "get",
            url: apiAddress,
          }).then((response) => {
            const res = response.data;
            const lists = [{ text: setList["default"], value: null }];
            for (const key in res) {
              // 初回インセンティブの時は月度インセンティブを表示しない
              if (
                form.value.type === "selectIncentiveFirst" &&
                res[key]["incentive_class"] === 20
              ) {
                // 月度はcontinue
                continue;
              }
              // 月度インセンティブの時は初回インセンティブを表示しない
              if (
                form.value.type === "selectIncentiveMonth" &&
                res[key]["incentive_class"] === 10
              ) {
                // 初回はcontinue
                continue;
              }

              let textVal = res[key][setList["code"]];
              if (setList["name"] !== "") {
                textVal += "（" + res[key][setList["name"]] + "）";
              }
              let valueVal = res[key]["id"];
              if ("id" in setList && setList["id"] !== "") {
                // idが存在する場合
                valueVal = res[key][setList["id"]];
              }
              lists.push({
                text: textVal,
                value: valueVal,
              });
            }
            form.value.items = lists;
          });
        } else {
          form.value.items = [{ text: setList["default"], value: null }];
        }
      }
    };

    // emit処理
    const controlChange = () => {
      emit("controlChange");
    };

    // データ取得
    loadData();

    return {
      mode,
      form,
      isLoggedIn,

      controlChange,
    };
  },
});
</script>
