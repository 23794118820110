<template>
  <v-app id="reportEstimate">
    <body class="report-body">
      <div
        v-for="(pageRow, key) in detailPage"
        :id="'report-page-' + key"
        :key="'report-page-' + key"
        class="report-page"
      >
        <header :id="'header_' + key">
          <div class="report-title-section-estimate">
            <div class="report-title-estimate">見積書</div>
          </div>
          <div v-if="key == 1">
            <div class="report-receiver-section-estimate">
              <div class="report-receiver-name-estimate">
                {{ estimateName }}
              </div>
              <div class="report-receiver-address-estimate">
                {{ estimateAddress }}
              </div>
            </div>
            <div class="report-total-monthly-section-estimate">
              <span class="report-total-label-estimate">月額費用</span>
              <span class="report-total-monthly-price-estimate">
                ¥{{ monthlyCharge }}
                <span class="report-total-tax">（税込）</span>
              </span>
            </div>
            <div class="report-total-first-section-estimate">
              <div class="report-total-label-estimate">初期費用</div>
              <div class="report-total-first-price-estimate">
                ¥{{ firstCharge }}
                <span class="report-total-tax">（税込）</span>
              </div>
            </div>
            <div class="report-condition-section-estimate">
              <div class="report-condition-label-estimate">発行日</div>
              <div class="report-condition-value-estimate">
                {{ todayLabel }}
              </div>
              <span
                class="report-condition-label-estimate report-condition-date-estimate"
              >
                見積期限
              </span>
              <span
                class="report-condition-value-estimate report-condition-date-estimate"
              >
                発行日より２週間
              </span>
            </div>
            <div class="report-slipnumber-section-estimate">
              <div class="report-slipnumber-estimate">
                会員ID：{{ customerCode }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="report-slipnumber-multiple-section-estimate">
              <div class="report-slipnumber-estimate">
                会員ID：{{ customerCode }}
              </div>
            </div>
          </div>
        </header>

        <section
          :id="'detail_' + key"
          :class="
            key == 1
              ? 'report-detail-section-estimate'
              : 'report-detail-multiple-section-estimate'
          "
        >
          <table :id="'table_' + key" class="report-detail-estimate">
            <thead id="thead">
              <tr>
                <th class="report-detail-est-name">品名</th>
                <th class="report-detail-est-price">単価</th>
                <th class="report-detail-est-quantity">数量</th>
                <th class="report-detail-est-amount">金額</th>
                <th class="report-detail-est-tax">税率</th>
              </tr>
              <tr>
                <td colspan="5" class="report-detail-top-estimate"></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in pageRow" :key="'tr-' + index">
                <td class="report-detail-name-estimate">
                  <span
                    v-if="row[6] == true"
                    class="report-detail-name-title-estimate"
                  >
                    {{ row[1] }}
                  </span>
                  <span v-else> {{ row[1] }}</span>
                </td>
                <td class="report-detail-price-estimate">
                  {{ row[2] }}
                </td>
                <td class="report-detail-quantity-estimate">
                  {{ row[3] }}
                </td>
                <td class="report-detail-amount-estimate">
                  {{ row[4] }}
                </td>
                <td class="report-detail-tax-estimate">
                  {{ row[5] }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <div v-if="key == 1">
          <section id="report-address" class="report-address-section-estimate">
            <div class="report-sender">
              <div class="report-sender">GEN株式会社</div>
              <div class="report-sender-line" />
              <div class="report-sender">〒153-0061</div>
              <div class="report-sender">
                東京都目黒区中目黒1-1-17 LANTIQUE BY IOQ 5F
              </div>
              <div class="report-sender-line" />
              <div class="report-sender">https://www.gen-square.com</div>
            </div>
          </section>

          <div class="report-sender-stamp-section-estimate">
            <img class="report-sender-stamp" src="@/assets/stamp.png" />
          </div>

          <div class="report-timestamp-section-estimate">
            <div class="report-timestamp-estimate">
              表示日：{{ dateTimeLabel }}
            </div>
          </div>
          <div class="report-pagenumber-section-estimate">
            <div class="report-pagenumber-estimate">
              page {{ key }} / {{ detailPageCount }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="report-timestamp-multiple-section-estimate">
            <div class="report-timestamp-estimate">
              表示日：{{ dateTimeLabel }}
            </div>
          </div>
          <div class="report-pagenumber-multiple-section-estimate">
            <div class="report-pagenumber-estimate">
              page {{ key }} / {{ detailPageCount }}
            </div>
          </div>
        </div>

        <div v-if="key == 1" class="report-remarks-section-estimate">
          <div class="report-remarks-wrapper-estimate">
            <div class="report-remarks-estimate">
              {{ estimateRemarks }}
            </div>
            <hr class="report-remarks-line-estimate" />
          </div>
        </div>

        <div v-if="key == 1" class="report-stampbox-section-estimate">
          <div class="report-stampbox-wrapper-estimate">
            <div class="report-stampbox-1-estimate"></div>
            <div class="report-stampbox-2-estimate"></div>
            <div class="report-stampbox-3-estimate"></div>
            <div class="report-stampbox-4-estimate"></div>
            <div class="report-stampbox-5-estimate"></div>
          </div>
        </div>

        <div class="report-logo-section-estimate">
          <img
            class="report-logo-image"
            src="@/assets/images/mygenLogoPaper.svg"
          />
        </div>
      </div>

      <ReportFooter />
    </body>
  </v-app>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { convertNumComma, getToday } from "@/utils/utilities.js";
import ReportFooter from "@/components/molecules/ReportFooter.vue";

export default defineComponent({
  name: "ReportEstimate",

  components: { ReportFooter },

  setup() {
    const route = useRoute();
    const localStorageKey = computed(() => {
      return route.params.key;
    });

    const orderData = computed(() => {
      return JSON.parse(localStorage.getItem(localStorageKey.value));
    });
    const todayLabel = computed(() => {
      return getToday("jp");
    });
    const dateTimeLabel = computed(() => {
      return getToday("jp-datetime");
    });

    const estimateName = orderData.value["estimate_name"];
    const estimateAddress = orderData.value["estimate_address"];
    const estimateRemarks = "備考　" + orderData.value["estimate_remarks"];
    const customerCode = orderData.value["customer_code"];

    const firstCharge = ref(
      convertNumComma(orderData.value["first_charge_including_tax"]) + " -"
    );
    const monthlyCharge = ref(
      convertNumComma(orderData.value["monthly_charge_including_tax"]) + " -"
    );
    const detailPage = ref({});
    const detailPageCount = ref(1);

    const setReportData = (res) => {
      const detailList = [];
      let detailCount = 0;

      // プラン
      detailList.push([++detailCount, "契約プラン", "", "", "", "", true]);
      detailList.push([
        ++detailCount,
        res["plan_name"],
        convertNumComma(res["basic_charge"]),
        1,
        convertNumComma(res["basic_charge"]),
        res["tax_rate"] + "%",
        false,
      ]);
      detailList.push([++detailCount, "アカウント数", "", "", "", "", true]);
      detailList.push([
        ++detailCount,
        "アカウント",
        convertNumComma(res["account_price"]),
        convertNumComma(res["account_quantity"]),
        convertNumComma(res["total_account_charge"]),
        res["tax_rate"] + "%",
        false,
      ]);

      // オプション
      if (
        res["option_fields"] &&
        Object.keys(res["option_fields"]).length > 0
      ) {
        detailList.push([
          ++detailCount,
          "契約オプション（追加オプション）",
          "",
          "",
          "",
          "",
          true,
        ]);
        for (const key in res["option_fields"]) {
          detailList.push([
            ++detailCount,
            res["option_fields"][key]["option_name"],
            convertNumComma(res["option_fields"][key]["option_fee"]),
            convertNumComma(res["option_fields"][key]["option_quantity"]),
            convertNumComma(res["option_fields"][key]["option_charge"]),
            res["tax_rate"] + "%",
            false,
          ]);
        }
      }

      // サポート料金
      if (detailCount == 10) ++detailCount;
      detailList.push([++detailCount, "サポート料金", "", "", "", "", true]);
      detailList.push([
        ++detailCount,
        "GMSPプログラム",
        res["support_rate"] + "%",
        1,
        convertNumComma(res["support_charge"]),
        res["tax_rate"] + "%",
        false,
      ]);

      // コイン購入
      if (res["coin_fields"]["coin_quantity"] > 0) {
        if (detailCount == 10) ++detailCount;
        detailList.push([++detailCount, "GEN-COIN", "", "", "", "", true]);
        detailList.push([
          ++detailCount,
          "GEN-COIN",
          convertNumComma(res["coin_fields"]["coin_rate"]),
          convertNumComma(res["coin_fields"]["coin_quantity"]),
          convertNumComma(res["coin_fields"]["coin_charge"]),
          res["tax_rate"] + "%",
          false,
        ]);
      }

      // クーポン
      let isCouponFirst = false;
      let isCouponMonthly = false;
      let isCouponLimitation = false;
      let discountCharge = "";
      let distountUnit = "";
      if (res["coupon_class"] === -10) {
        isCouponFirst = true;
        discountCharge = res["discount_charge_of_first"];
        distountUnit = "初期費用";
        firstCharge.value =
          convertNumComma(res["first_charge_after_discount_including_tax"]) +
          " -";
      } else if (res["coupon_class"] === -20) {
        discountCharge = res["discount_charge_of_monthly"];
        isCouponMonthly = true;
        discountCharge = res["discount_charge_of_monthly"];
        distountUnit = "毎月";
        monthlyCharge.value =
          convertNumComma(res["monthly_charge_after_discount_including_tax"]) +
          " -";
      } else if (res["coupon_class"] > 0) {
        isCouponLimitation = true;
        discountCharge = res["discount_charge_of_monthly"];
        distountUnit = res["coupon_class"] + "ヶ月";
        monthlyCharge.value =
          convertNumComma(res["monthly_charge_after_discount_including_tax"]) +
          " -";
      }
      if (isCouponFirst || isCouponMonthly || isCouponLimitation) {
        if (detailCount == 10) ++detailCount;
        detailList.push([++detailCount, "クーポン", "", "", "", "", true]);
        detailList.push([
          ++detailCount,
          res["coupon_name"],
          convertNumComma(discountCharge),
          distountUnit,
          convertNumComma(discountCharge),
          res["tax_rate"] + "%",
          false,
        ]);
      }

      const detailFirstCount = 12; // 1ページ目は明細12行
      const detailSecondCount = 30; // 2ページ目以降は明細30行
      let isFirst = true;
      let tempPageCount = 1;
      const tempDetailPage = {};
      // detailList は配列（辞書型ではない）なので for of でループ処理
      for (const line of detailList) {
        if (isFirst && line[0] % detailFirstCount === 0) {
          // 1ページ目の明細行数
          isFirst = false;
          tempPageCount++;
        } else if (
          !isFirst &&
          (line[0] - detailFirstCount) % detailSecondCount === 0
        ) {
          // 2ページ以降の明細行数
          tempPageCount++;
        }
        if (tempDetailPage[tempPageCount]) {
          tempDetailPage[tempPageCount].push(line);
        } else {
          tempDetailPage[tempPageCount] = [line];
        }
      }
      detailPage.value = reactive(tempDetailPage);
      detailPageCount.value = tempPageCount;
    };

    // データ取得
    setReportData(orderData.value);

    return {
      orderData,
      todayLabel,
      dateTimeLabel,

      estimateName,
      estimateAddress,
      estimateRemarks,
      customerCode,
      firstCharge,
      monthlyCharge,
      detailPage,
      detailPageCount,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/report.scss";
</style>
