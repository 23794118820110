<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container
      :class="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'px-16 py-10'
      "
    >
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in items" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, onMounted, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";

export default defineComponent({
  name: "ListSetting",

  components: {
    DivTitle,
    OverlayProgress,
  },

  setup() {
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });

    const listTitle = ref("サーバー設定");
    const titleComment = ref(["サーバー設定値を確認できます。"]);

    const items = ref({});
    const loading = ref(false);

    const progress = ref(false);
    const formKey = ref(createKey());

    const loadData = () => {
      progress.value = true;
      items.value = {};
      if (!isStaff.value) {
        return false;
      }
      // api:サーバー設定情報取得
      api({
        method: "get",
        url: "/serversetting/",
      }).then((response) => {
        const res = response.data;
        items.value = {
          get_date_now: res["get_date_now"],
          get_datatime_now: res["get_datatime_now"],
          get_localtime: res["localtime(timezone.now)"],
          get_localtime_date: res["localtime(timezone.now).date()"],
          // "Git current_branch": res["git_info"]["current_branch"],
          // "Git hash": res["git_info"]["hash"],
          DEBUG: res["env_info"]["DEBUG"],
          LOGGING_LEVEL: res["env_info"]["LOGGING_LEVEL"],
          BROWSABLE_API: res["env_info"]["BROWSABLE_API"],
          STATIC_ROOT: res["env_info"]["STATIC_ROOT"],
          MEDIA_ROOT: res["env_info"]["MEDIA_ROOT"],
          DEFAULT_FROM_EMAIL: res["env_info"]["DEFAULT_FROM_EMAIL"],
          DATABASE_NAME: res["env_info"]["DATABASE_NAME"],
        };
        progress.value = false;
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      listTitle,
      titleComment,

      items,
      loading,

      progress,
      formKey,
    };
  },
});
</script>
