<template>
  <div>
    <p>
      <span name="divTitle-listTitle" class="text-h6 font-weight-bold">
        {{ listTitle }}<ChipStaff v-if="isStaffOnly" class="ml-2 mb-1" />
        <ChipStaff
          v-if="isBrotherOnly"
          :propsIsBrother="true"
          class="ml-2 mb-1"
        />
        <v-tooltip bottom v-if="isTooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="grey darken-1"
              dark
              v-bind="attrs"
              v-on="on"
              class="ml-2 mb-1"
            >
              mdi-help-circle
            </v-icon>
          </template>
          <span
            v-for="(value, index) in toootipText"
            :key="'tooltip-' + index"
            class="text-body-2 mb-0"
          >
            {{ value }}<br />
          </span>
        </v-tooltip>
      </span>
    </p>
    <p
      v-for="(value, index) in listComment"
      :key="'span-' + index"
      class="text-body-2 mb-0"
    >
      {{ value }}
    </p>
    <p v-if="isTerms" class="text-body-2 mb-0">
      ・GENの利用規約は
      <a href="/static/assets/pdf/GEN_TOS.pdf" target="_blank">こちら</a>
      からご覧になれます
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import ChipStaff from "@/components/atoms/ChipStaff.vue";

export default defineComponent({
  name: "DivTitle",

  props: {
    propsTitle: { type: String, default: "" },
    propsComment: { type: Array, default: () => [] },
    propsIsStaffOnly: { type: Boolean, default: false },
    propsIsBrotherOnly: { type: Boolean, default: false },
    propsTooltipText: { type: Array, default: () => [] },
    propsIsTerms: { type: Boolean, default: false },
  },

  components: {
    ChipStaff,
  },

  setup(props) {
    const listTitle = ref(props.propsTitle);
    const listComment = ref(props.propsComment);
    const isStaffOnly = ref(props.propsIsStaffOnly);
    const isBrotherOnly = ref(props.propsIsBrotherOnly);
    const toootipText = ref(props.propsTooltipText);
    const isTerms = ref(props.propsIsTerms);

    const isTooltip = computed(() => {
      if (toootipText.value.length === 0) {
        return false;
      }
      return true;
    });

    return {
      listTitle,
      listComment,
      isStaffOnly,
      isBrotherOnly,
      toootipText,
      isTooltip,
      isTerms,
    };
  },
});
</script>
