import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { setAuthFormDict, setFormDict } from "@/utils/formUtility.js";
import Auth from "@/views/base/BaseAuth.vue";
import AuthModel from "@/views/model/ModelAuth.vue";
import BaseHome from "@/views/base/BaseHome.vue";
import HomeChildren from "@/views/pages/HomeChildren.vue";
import ModelList from "@/views/model/ModelList.vue";
import ListService from "@/views/pages/ListService.vue";
import ListCoin from "@/views/pages/ListCoin.vue";
import ListInvoice from "@/views/pages/ListInvoice.vue";
import ListUser from "@/views/pages/ListUser.vue";
import ListSetting from "@/views/pages/ListSetting.vue";
import FormSupport from "@/views/pages/FormSupport.vue";
import ReportContract from "@/views/report/ReportContract.vue";
import ReportEstimate from "@/views/report/ReportEstimate.vue";
import ReportInvoice from "@/views/report/ReportInvoice.vue";
import ReportPayment from "@/views/report/ReportPayment.vue";

Vue.use(VueRouter);

const homeChildren = [];
const formDict = setFormDict();
const notStaffChildren = ["brothercustomer", "brotherincentive"];
for (const key in formDict) {
  homeChildren.push({
    isStaff: notStaffChildren.includes(key) ? false : true,
    path: key + "-list",
    name: key + "List",
    component: ModelList,
    props: {
      propsFormType: key,
    },
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
  });
}

const authChildren = [];
const authFormDict = setAuthFormDict();
for (const key in authFormDict) {
  authChildren.push({
    path: key,
    name: key,
    component: AuthModel,
    props: {
      propsFormType: key,
    },
  });
}

// ルート定義
const routes = [
  {
    path: "/",
    component: BaseHome,
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "homeChildren",
        component: HomeChildren,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "customer",
        name: "customer",
        component: ListUser,
        props: {
          propsFormType: "customer",
          propsStoreId: "customerId",
        },
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "invoice-address",
        name: "invoiceAddress",
        component: ListUser,
        props: {
          propsFormType: "invoiceaddress",
          propsStoreId: "customerId",
        },
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "set-password",
        name: "setPassword",
        component: ListUser,
        props: {
          propsFormType: "setpassword",
        },
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "set-email",
        name: "setEmail",
        component: ListUser,
        props: {
          propsFormType: "setemail",
        },
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "service-reference",
        name: "referenceService",
        component: ListService,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "coin-reference",
        name: "referenceCoin",
        component: ListCoin,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "invoice-reference",
        name: "referenceInvoice",
        component: ListInvoice,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "support",
        name: "support",
        component: FormSupport,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
      {
        path: "/server-setting",
        name: "serverSetting",
        component: ListSetting,
        // ログインが必要な画面には「requiresAuth」フラグを付ける
        meta: { requiresAuth: true },
      },
    ].concat(homeChildren),
  },
  {
    path: "/report-contract/:id",
    name: "reportContract",
    component: ReportContract,
    props: true,
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
  },
  {
    path: "/report-estimate/:key",
    name: "reportEstimate",
    component: ReportEstimate,
    props: true,
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
  },
  {
    path: "/report-invoice/:id",
    name: "reportInvoice",
    component: ReportInvoice,
    props: true,
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
  },
  {
    path: "/report-payment/:id",
    name: "reportPayment",
    component: ReportPayment,
    props: true,
    // ログインが必要な画面には「requiresAuth」フラグを付ける
    meta: { requiresAuth: true },
  },

  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "activation/:uid/:token",
        name: "activation",
        component: AuthModel,
        props: {
          propsFormType: "activation",
          propsIsUidToken: true,
        },
      },
      {
        path: "resetpassword/:uid/:token",
        name: "resetpassword",
        component: AuthModel,
        props: {
          propsFormType: "resetpassword",
          propsIsUidToken: true,
        },
      },
      {
        path: "/",
        redirect: "login/",
      },
    ].concat(authChildren),
  },

  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

// スタッフ専用ページのリスト作成
const staffFormName = [];
for (const key in routes) {
  if (routes[key]["isStaff"]) {
    staffFormName.push(routes[key]["name"]);
  }
  if (routes[key]["children"]) {
    const childArr = routes[key]["children"];
    for (const childKey in childArr) {
      if (childArr[childKey]["isStaff"]) {
        staffFormName.push(childArr[childKey]["name"]);
      }
    }
  }
}

// ルータインスタンスを作成
const router = new VueRouter({
  mode: "history",
  routes,
});

/**
 * 画面遷移をする直前に毎回実行されるナビゲーションガード
 */
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.auth.isLoggedIn;
  const isStaff = store.state.auth.isStaff;
  const isAccountChange = store.state.auth.isAccountChange;
  const token = localStorage.getItem("access");
  // console.log("from.path=", from);
  // console.log("to.path=", to.path);

  // ログインが必要な画面に遷移しようとした場合
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // ログインしていない場合
    if (!isLoggedIn) {
      console.log("User is not logged in.");
      // まだ認証用トークンが残っていればユーザー情報を再取得
      if (token != null) {
        console.log("Try to renew user info.");
        store
          .dispatch("auth/renew")
          .then(() => {
            // 再取得できたらそのまま次へ
            console.log("Succeeded to renew. So, free to next.");
            const isStaff = store.state.auth.isStaff;
            if (!isStaff && staffFormName.includes(to.name)) {
              // スタッフ専用ページへの権限外アクセスはログイン画面へ
              forceToLoginInitialization();
            }
            if (!isStaff && !isAccountChange && to.name === "setEmail") {
              // アカウント変更禁止時はログイン画面へ
              forceToLoginInitialization();
            }
            next();
          })
          .catch(() => {
            // 再取得できなければログイン画面へ
            forceToLogin(to);
          });
      } else {
        // 認証用トークンが無い場合はログイン画面へ
        forceToLogin(to);
      }
    } else {
      // ログインしている場合はそのまま次へ
      console.log("User is already logged in. So, free to next.");
      if (!isStaff && staffFormName.includes(to.name)) {
        // スタッフ専用ページへの権限外アクセスはログイン画面へ
        forceToLoginInitialization();
      }
      if (!isStaff && !isAccountChange && to.name === "setEmail") {
        // アカウント変更禁止時はログイン画面へ
        forceToLoginInitialization();
      }
      next();
    }
  } else {
    // ログインが不要な画面であればそのまま次へ
    console.log("Go to public page.");
    next();
  }
});

/**
 * ログイン画面へ強制送還
 */
function forceToLogin(to) {
  console.log("Force to login page.");
  router.replace({
    path: "/auth/login",
    // 遷移先のURLはクエリ文字列として付加
    query: { next: to.fullPath },
  });
}

/**
 * ログイン画面へ強制送還（URLリセット）
 */
function forceToLoginInitialization() {
  console.log("Force to login page. (Initialization)");
  router.replace({
    path: "/auth/login",
  });
}

export default router;
