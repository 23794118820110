import { getDateObject } from "@/utils/utilities.js";
import { setCouponClass } from "@/utils/selectUtility.js";
import {
  requiredRules,
  selectedRules,
  dateRules,
  numericIntegerRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "クーポン",
  apiAddress: "/coupon/",
  listSortBy: ["coupon_sort", "coupon_code"],
  listSortDesc: [false, false],
  isCsvImport: true,
};

const forms = [
  {
    column: "coupon_code",
    label: "クーポンコード",
    type: "text",
    rules: [requiredRules],
    importUnique: true,
  },
  {
    column: "coupon_name",
    label: "クーポン",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "coupon_class",
    label: "区分",
    type: "select",
    items: setCouponClass(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "discount_charge",
    label: "料金",
    type: "number",
    rules: [requiredRules, numericIntegerRules],
    cols: 6,
  },
  {
    column: "start_date",
    label: "受付開始日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "end_date",
    label: "受付終了日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "coupon_sort",
    label: "並び順",
    type: "text",
    rules: [],
    cols: 6,
  },
  {
    column: "coupon_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "クーポンコード",
    value: "coupon_code",
    align: "start",
  },
  {
    text: "クーポン",
    value: "coupon_name",
  },
  {
    text: "区分",
    value: "coupon_class",
    type: "select",
    items: setCouponClass(),
    align: "center",
  },
  {
    text: "料金",
    value: "discount_charge",
    type: "number",
    align: "end",
  },
  {
    text: "受付開始日",
    value: "start_date",
    align: "center",
  },
  {
    text: "受付終了日",
    value: "end_date",
    align: "center",
  },
  {
    text: "並び順",
    value: "coupon_sort",
  },
  {
    text: "コメント",
    value: "coupon_comment",
  },
];

const searchDateFrom = {
  label: "受付終了日付（以降）",
  column: "end_date",
  menu: false,
  default: getDateObject(),
  formula: "gte", // 以上
};

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
};
